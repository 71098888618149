import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchLpSymbolMapUpdate, onlyFetchGlobalSymbols } from '../../redux/actions/system-settings-actions'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const schema = {
  Symbol: yup.string().required(),
  LpSymbol: yup.string().required(),
  ContractMultiplier: yup
    .string()
    .matches(/^[0-9.]+$/gi)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0)
    .required(),
}

const LpSymbolMapRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const dispatch = useDispatch()

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      Symbol: { label: item.Symbol, value: item.Symbol },
    },
    schema,
  )

  const [globalSymbols, setGlobalSymbols] = useState<string[]>([])

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    if (!globalSymbols.includes(item.Symbol) && type === 'modify') {
      new Promise(resolve => {
        resolve(dispatch(fetchLpSymbolMapUpdate({ action: 'delete', params, body: { ...item, Name: inputState.Name } })))
      }).then(e => {
        dispatch(fetchLpSymbolMapUpdate({ action: 'add', params, body: { ...inputState, Symbol: inputState.Symbol.value, Name: inputState.Name } }))
      })
    } else {
      dispatch(fetchLpSymbolMapUpdate({ action: type, params, body: { ...inputState, Symbol: inputState.Symbol.value, Name: inputState.Name } }))
    }

    dispatch(hideRightBar())
  }

  useEffect(() => {
    onlyFetchGlobalSymbols(params).then((symbols: any) => {
      setGlobalSymbols(symbols)
      if (type === 'add') {
        setInputState({ ...item, Symbol: { label: symbols, value: symbols } && { label: symbols[0], value: symbols[0] } })
      }

      if (!symbols.includes(item.Symbol) && type === 'modify') {
        setInputState({
          ...item,
          Symbol: { label: 'Unknown', value: 'Unknown' },
        })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const newGlobalSymbols = globalSymbols.map((item: any) => {
    return {
      label: item,
      value: item,
    }
  })

  const inputs = buildControlsExtTwoPerLine(
    [
      sselectInput('Symbol', [...newGlobalSymbols]),
      textInput('LpSymbol', type === 'modify'),
      textInput('ContractMultiplier'),
      textInput('DealCurrency').skipWhen(params.LpType !== 'ADSSo' && params.LpType !== 'IG' && params.LpType !== 'IGDMA'),
      textInput('Type ').skipWhen(params.LpType !== 'AdvancedMarkets'),
    ],
    inputState,
    setInputState,
    'lp-symbol-map',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}
export default LpSymbolMapRightbar
