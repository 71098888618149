import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedAggTypes, FeedAgregationEntity, FeedAgregationExtEntity } from '../../entity/feeding'
import { addFeedAggregation, changeFeedAggregation } from '../../redux/actions/execution/execution-actions'
import { feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import MultiSelectInput from '../inputs/MultiSelectInput'
import SelectInput from '../inputs/SelectInput'
import TextInput from '../inputs/TextInput'
import { useFormValidation } from '../../hooks/useFormValidation'
import { AppAccordion } from '@t4b/core/lib'
import * as yup from 'yup'
import { folderNameSchema } from '../../utils/schema-utils'
import { IRightbar } from './rightbar-types'

const schema = {
  Name: folderNameSchema(),
  Type: yup.string().required(),
  FeedersNames: yup.array().required(),
}

const FeedAggregationRightbar: React.FC<IRightbar> = ({ data: { type, item } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new FeedAgregationExtEntity(item), schema)
  const dispatch = useDispatch()
  const { feedAgregations, lp } = useSelector((state: any) => state.feedingConfiguration)
  const ref = useRef<any>(null)

  const handleChange = (newState: any) => {
    const updatedState = {
      ...newState,
      Feeders: newState.FeedersNames?.map((feederName: any) => feederName.value) ?? [],
    }
    setInputState(updatedState)
  }

  const handleSave = () => {
    if (!isValid()) {
      if (ref.current) {
        ref.current.open()
      }
      return
    }
    const data = new FeedAgregationEntity(inputState)
    if (type === 'add' || type === 'clone') {
      dispatch(addFeedAggregation(data))
    } else {
      dispatch(changeFeedAggregation(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const alreadyExist = () => {
    if (type === 'add' && feedAgregations.find((feed: FeedAgregationExtEntity) => feed.Name === inputState.Name)) {
      errors.Name = true
      return 'feed-already-exists'
    }
    if (type === 'modify' && feedAgregations.find((platform: FeedAgregationExtEntity) => platform.Name === inputState.Name && platform.Id !== inputState.Id)) {
      errors.Name = true
      return 'feed-platform-already-exists'
    }
    return ''
  }

  const content = (
    <>
      <div className="row">
        <TextInput
          state={inputState}
          setState={setInputState}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="Name"
          label="feed-agregation.Name"
          className="col-6"
          errorText={alreadyExist()}
        />
        <SelectInput
          state={inputState}
          setState={setInputState}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="Type"
          label="feed-agregation.Type"
          options={Object.values(FeedAggTypes)}
          className="col-6"
        />
      </div>

      <MultiSelectInput
        state={inputState}
        setState={handleChange}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        name="FeedersNames"
        label="feed-agregation.Feeders"
        options={lp.map((lpItem: any) => ({
          value: lpItem.Id,
          label: lpItem.Name,
        }))}
      />
    </>
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`feed-aggregation.${type}`} />,
          item: content,
        }}
        ref={ref}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}
export default FeedAggregationRightbar
