import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { LiquidityProvider } from '../../entity/feeding'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addFeedingLp, changeFeedingLp, feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const FeedingLpRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new LiquidityProvider(item), LiquidityProvider.schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    const data = new LiquidityProvider(inputState)
    if (type === 'clone') {
      dispatch(addFeedingLp(data))
    } else {
      dispatch(changeFeedingLp(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`feeding-lp.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Name'), textInput('Type', true), textInput('Depth')],
          inputState,
          setInputState,
          'feeding-lp',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default FeedingLpRightbar
