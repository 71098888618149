import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { ExecutionLpBRule, PriceOptionTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { changeExecutionLpRule, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, Converter,  selectInput, textInput } from '../../utils/controls'
import { percentSchema } from '../../utils/schema-utils'
import { IRightbar } from './rightbar-types'
import { useTranslate } from '../../hooks/useTranslate'

interface IExecutionLiquidityProviderRightbar extends IRightbar {
  lpTypes: string[]
}

const schema = {
  DelayFrom: yup.number().required(),
  DelayTo: yup.number().required(),
  TicksThreshold: yup.number().integer().required(),
  PriceOption: yup.string().required(),
  WorstPricePercentage: percentSchema().notRequired(),
}

const ExecutionLpBRightbar: React.FC<IExecutionLiquidityProviderRightbar> = React.memo(({ lpTypes, data: { type, item } }) => {
  const starRule = item.Rules[item.Rules.length - 1] || new ExecutionLpBRule()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(starRule, schema)
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const translate = useTranslate()

  const handleClick = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      changeExecutionLpRule({
        rule: new ExecutionLpBRule(inputState),
        params: { Lp: item.Name },
      }),
    )
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  function roundTypesConverter(translate: Converter): Converter {
    return function (option: string) {
      return translate(`test.${option}`)
    }
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`execution-lp.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Name').disabled(true), selectInput('Type').optionItems(lpTypes).disabled(true)],
          item,
          () => {},
          'execution-lp',
        )}
        {buildControlsExtTwoPerLine(
          [
            textInput('DelayFrom'),
            textInput('DelayTo'),
            textInput('TicksThreshold'),
            checkboxInput('ConsiderConnectorSpread'),
            selectInput('PriceOption')
              .optionItems(Object.values(PriceOptionTypes))
              .converter(roundTypesConverter(translate))
              .skipWhen(!gateway.EnablePriceOption),
            textInput('WorstPricePercentage').skipWhen(inputState.PriceOption !== PriceOptionTypes.WORST_PERCENTAGE_PRICE || !gateway.EnablePriceOption),
          ],
          inputState,
          setInputState,
          'execution-lp',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ExecutionLpBRightbar
