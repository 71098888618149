import { AppAccordion } from '@t4b/core/lib'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchPlatformExtSymbolsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, selectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const schema = {
  PlatformSymbol: yup.string().required(),
  ContractMultiplier: yup.string().required(),
  ContractSize: yup.number().required(),
  Type: yup.string().required(),
  BaseCurrency: yup.string().required(),
  QuoteCurrency: yup.string().required(),
  CanBeUsedForConvert: yup.boolean().required(),
  Digits: yup.number().required(),
  VolumeStep: yup.number().required(),
}

const ExtSymbolsRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchPlatformExtSymbolsUpdate({
        action: type,
        params: { ...params },
        body: inputState,
      }),
    )
    dispatch(hideRightBar())
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('PlatformSymbol').disabled(type === 'modify'),
      textInput('ContractMultiplier'),
      textInput('ContractSize'),
      selectInput('Type', ['Forex', 'CFD']),
      textInput('BaseCurrency'),
      textInput('QuoteCurrency'),
      checkboxInput('CanBeUsedForConvert'),
      textInput('Digits'),
      textInput('VolumeStep'),
    ],
    inputState,
    setInputState,
    'fix-symbols',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.title`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
})

export default ExtSymbolsRightbar
