import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Day, ScheduleType } from '../entity/configuration'
import { buildControlsExtTwoPerLine, mselectInput, selectInput, timeInput, timespanInput } from '../utils/controls'
import { buildMultiselectOptionsFromEnum } from '../utils/multiselect-utils'
import { useTranslate } from '../hooks/useTranslate'
import { AppAccordion } from '@t4b/core/lib'

interface IAppSchedule {
  state: any
  touched: any
  setTouched: any
  errors: any
  isValid: any

  setState(state: any): any
}

function scheduleTypesConverter(translate: any) {
  return function (option: string) {
    return translate(`schedule-type.${option}`)
  }
}

const AppSchedule = forwardRef(({ state, setState, touched, setTouched, errors, isValid }: IAppSchedule, ref: any) => {
  const translate = useTranslate()

  const alwaysInputs = buildControlsExtTwoPerLine(
    [selectInput('ScheduleType').optionItems([ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]).converter(scheduleTypesConverter(translate))],
    state,
    setState,
    'schedule',
  )

  const periodicInputs = buildControlsExtTwoPerLine(
    [
      selectInput('ScheduleType').optionItems([ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]),
      mselectInput('Days', buildMultiselectOptionsFromEnum(Day, translate, 'day')),
      timespanInput('Start'),
      timespanInput('Duration'),
    ],
    state,
    setState,
    'schedule',
    touched,
    setTouched,
    errors,
  )

  const rangeInputs = buildControlsExtTwoPerLine(
    [selectInput('ScheduleType').optionItems([ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]), timeInput('Begin'), timeInput('End')],
    state,
    setState,
    'schedule',
  )

  let content = null
  switch (state.ScheduleType) {
    case ScheduleType.Always:
      content = alwaysInputs
      break
    case ScheduleType.Periodic:
      content = periodicInputs
      break
    case ScheduleType.Range:
      content = rangeInputs
      break
  }

  return (
    <AppAccordion
      item={{
        title: <FormattedMessage id="schedule.title" />,
        item: content,
      }}
      ref={ref}
    />
  )
})

export default AppSchedule
