import { AggregationPoolType } from '../entity/configuration'

export function isSwitchUnsupportedPoolType(type: AggregationPoolType | string): boolean {
  if (type === AggregationPoolType.SingleBook || type === AggregationPoolType.MultiBook || type === '') {
    return false
  }
  return true
}

export function typeOfPool(gateway: any, name: string) {
  if (gateway && Array.isArray(gateway.Pools)) {
    const pool = gateway.Pools.find((pool: any) => pool.Name === name)
    if (pool) {
      return pool.AggregationType
    }
  }
  return ''
}

export function isMixedPool(gateway: any, name: string): boolean {
  if (gateway && Array.isArray(gateway.Pools)) {
    const pool = gateway.Pools.find((pool: any) => pool.Name === name)
    if (pool) {
      return pool.Type === 'Mixed'
    }
  }
  return false
}
