import React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import en from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import { localAsUTC, UTCAsLocal } from '../../utils/time-utils'
import classNames from 'classnames'

registerLocale('en', en)
registerLocale('ja', ja)

interface IInputProps {
  state: any
  name: string
  label: string
  disabled?: boolean
  className?: string
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date

  setState(newState: any): void
}

const DateTimeInput: React.FC<IInputProps> = ({
  state,
  name,
  setState,
  label,
  disabled,
  className,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  minDate,
  maxDate,
}) => {
  const intl = useIntl()
  const onChange = (date: Date) => {
    setState({
      ...state,
      [name]: localAsUTC(date).getTime(),
    })
  }

  let isInvalid = false

  try {
    yup.number().required().validateSync(state[name])
  } catch (e) {
    isInvalid = true
  }

  let selectedDate = new Date(state[name])
  selectedDate = UTCAsLocal(selectedDate)

  return (
    <Form.Group className={classNames('d-flex', 'flex-column', className)}>
      <Form.Label>
        <FormattedMessage id={label} tagName="span" />
        <span>:</span>
      </Form.Label>
      <DatePicker
        locale={intl.locale}
        disabled={disabled}
        className={classNames('form-control', isInvalid && 'isInvalid')}
        selected={selectedDate}
        onChange={onChange}
        timeCaption="time"
        dateFormat={showTimeSelectOnly ? 'HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss'}
        timeFormat="HH:mm:ss"
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        minDate={minDate}
        maxDate={maxDate}
      />
      {isInvalid ? (
        <div className="d-block invalid-feedback">
          <FormattedMessage id="field-validation-error" tagName="span" />
        </div>
      ) : null}
    </Form.Group>
  )
}

export default React.memo(DateTimeInput)
