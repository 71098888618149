import { v4 } from 'ip-regex'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { IMultiSelectItem } from '../../entity/multiselect'
import { UserEntity } from '../../entity/system-settings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { useTranslate } from '../../hooks/useTranslate'
import { UserRoles, UserType, userTypeRoles } from '../../redux/actions/authentication'
import { fetchUsersUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput, translator } from '../../utils/controls'
import { buildSelectOption } from '../../utils/multiselect-utils'
import { passwordSchema } from '../../utils/schema-utils'
import MultiSelectInput from '../inputs/MultiSelectInput'
import PasswordInput from '../inputs/PasswordInput'
import SelectInput from '../inputs/SelectInput'
import { IRightbar } from './rightbar-types'
import { RootState } from '../../redux/reducers/rootReducer'

function checkboxBlockInitial(blockName: string, items: UserRoles[], state: any): object {
  for (const item of items) {
    if (!state[item]) {
      return { [blockName]: false }
    }
  }
  return { [blockName]: true }
}

const CheckboxBlock: React.FC<any> = ({ blockName, items, state, setState, translatePrefix }) => {
  const [block, setBlock] = useState(checkboxBlockInitial(blockName, items, state))

  useEffect(() => {
    setBlock(checkboxBlockInitial(blockName, items, state))
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  const setBlockExt = (newState: any) => {
    const updatedState = { ...state }
    if (newState[blockName]) {
      items.forEach((item: UserRoles) => {
        updatedState[item] = true
      })
    } else {
      items.forEach((item: UserRoles) => {
        updatedState[item] = false
      })
    }
    setBlock(newState)
    setState(updatedState)
  }

  const setBlockItems = (newState: any) => {
    const newBlockState = { [blockName]: true }
    for (const item of items) {
      if (!newState[item]) {
        newBlockState[blockName] = false
      }
    }
    setBlock(newBlockState)
    setState(newState)
  }

  return (
    <div>
      {buildControlsExtTwoPerLine([checkboxInput(blockName)], block, setBlockExt, translatePrefix)}
      <div className="ml-20">
        {buildControlsExtTwoPerLine(
          items.map((item: string) => checkboxInput(item)),
          state,
          setBlockItems,
          translatePrefix,
        )}
      </div>
    </div>
  )
}

const UserRightsComponent: React.FC<any> = ({ item, children }) => {
  if (item.Id === 1) {
    return null
  }
  return children
}

function arrayToRoles(roles: UserRoles[]): object {
  if (Array.isArray(roles)) {
    return Object.assign({}, ...roles.map((role: UserRoles) => ({ [role]: true })))
  }
  return {} as { [key: string]: any }
}

function allRoles(): object {
  const adminRoles = userTypeRoles[UserType.Admin]
  return Object.assign({}, ...adminRoles.map((role: UserRoles) => ({ [role]: false })))
}

function getSchema(type: string): object {
  return {
    Username: yup.string().required(),
    Description: yup.string().notRequired(),
    Password: passwordSchema(type),
    AllowedAddress: yup.array().required(),
  }
}

const UsersRightbar: React.FC<IRightbar> = ({ data: { item, type, params } }) => {
  const users = useSelector((state: RootState) => state.users)
  const [common, setCommon, touched, setTouched, errors, isValid] = useFormValidation(new UserEntity(item), getSchema(type))
  const [inputState, setInputState] = useState<any>({ ...allRoles(), ...arrayToRoles(item.Roles) })
  // const [userType, setUserType] = useState({ UserType: UserType.Custom });
  const dispatch = useDispatch()
  const translate = useTranslate()

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchUsersUpdate({
        action: type,
        body: {
          ...common,
          AllowedAddress: common.AllowedAddress?.map((item: IMultiSelectItem) => item.value).join(';'),
          Roles: Object.keys(inputState).filter((key: string) => inputState[key]),
        },
        params,
      }),
    )
    dispatch(hideRightBar())
  }

  const setUserTypeExt = (newType: any) => {
    setInputState({ ...allRoles(), ...arrayToRoles(userTypeRoles[newType.UserType]) })
    setCommon(newType)
  }

  const handleCreateOption = (value: string) => {
    let isValidIp = false

    if (v4({ exact: true }).test(value)) {
      isValidIp = true
    } else if (/-/.test(value)) {
      const [start, end] = value.split('-')

      if (v4({ exact: true }).test(start) && v4({ exact: true }).test(end)) {
        isValidIp = true
      }
    } else if (value === '*') {
      isValidIp = true
    }

    if (isValidIp) {
      let newAllowed = [buildSelectOption(value)]

      if (Array.isArray(common.AllowedAddress)) {
        newAllowed = common.AllowedAddress.filter((item: IMultiSelectItem) => item.value !== '*').concat(buildSelectOption(value))
      }

      setCommon({
        ...common,
        AllowedAddress: newAllowed,
      })
    }
  }

  const test = () => {
    const tets = [...users.data]
    if (type === 'add' && tets.find(({ Username }) => Username === common.Username)) {
      errors.Username = true
      return 'User name already exists'
    }
    return 'User name already exists'
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`users.rightbar.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Username').errorMessage(test()), textInput('Description')],
          common,
          setCommon,
          'users',
          touched,
          setTouched,
          errors,
        )}

        <Row>
          <Col>
            <PasswordInput
              state={common}
              setState={setCommon}
              touched={touched}
              setTouched={setTouched}
              errors={errors}
              name="Password"
              label="users.Password"
              mode={type === 'add' || type === 'clone' ? 'setup' : 'change'}
            />

            {item.Id !== 1 && (
              <SelectInput
                state={common}
                setState={setUserTypeExt}
                options={Object.values(UserType)}
                name="UserType"
                label="users.UserType"
                convert={translator(translate, 'users')}
              />
            )}
          </Col>

          <Col>
            <MultiSelectInput
              state={common}
              setState={setCommon}
              name="AllowedAddress"
              touched={touched}
              setTouched={setTouched}
              errors={errors}
              label="users.AllowedAddress"
              options={[]}
              isCreatable={true}
              onCreateOption={handleCreateOption}
            />
          </Col>
        </Row>
        <UserRightsComponent item={item}>
          {buildControlsExtTwoPerLine(
            [
              checkboxInput('summary'),
              checkboxInput('exposure'),
              checkboxInput('positions'),
              checkboxInput('trading-history'),
              checkboxInput('bbi-integration'),
            ],
            inputState,
            setInputState,
            'users',
          )}

          <CheckboxBlock
            blockName="risk-tool"
            items={['new-switch', 'active-switches', 'risk-tool-history', 'auto-switch', 'active-rules']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          <CheckboxBlock
            blockName="volume-transfer"
            items={['volume-transfer', 'volume-transfer-history']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          {buildControlsExtTwoPerLine([checkboxInput('execution-configuration'), checkboxInput('feeding-configuration')], inputState, setInputState, 'users')}

          <CheckboxBlock
            blockName="ce"
            items={['ce-active', 'ce-failed', 'ce-rules', 'ce-history']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          <CheckboxBlock
            blockName="consolidation"
            items={['consolidation-rules', 'consolidation-history']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          <CheckboxBlock blockName="backups" items={['backups-active', 'backups-rules']} state={inputState} setState={setInputState} translatePrefix="users" />

          <CheckboxBlock
            blockName="drop-copy"
            items={['drop-copy-settings', 'drop-copy-sessions']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          <CheckboxBlock
            blockName="system-settings"
            items={['common', 'platforms', 'lps', 'symbol-map', 'edit-users']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />

          <CheckboxBlock blockName="monitoring" items={['telemetry', 'logs']} state={inputState} setState={setInputState} translatePrefix="users" />

          <CheckboxBlock
            blockName="lp-performance"
            items={['quotes', 'spreads', 'trading']}
            state={inputState}
            setState={setInputState}
            translatePrefix="users"
          />
        </UserRightsComponent>

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default UsersRightbar
