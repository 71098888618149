import { AppRightBar } from '@t4b/core/lib'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import AccountsRightbar from './AccountsRightbar'
import AddPlatform from './AddPlatform'
import AggregationPoolRightbar from './AggregationPoolRightbar'
import AutoSwitchRightbar from './AutoSwitchRightbar'
import CeRuleRightbar from './CeRuleRightbar'
import CeVolumeDistributionRightbar from './CeVolumeDistributionRightbar'
import ConsolidationRightbar from './ConsolidationRightbar'
import DatabaseRightbar from './DatabaseRightbar'
import DropCopyRightbar from './DropCopyRightbar'
import DropCopySettingsRightbar from './DropCopySettingsRightbar'
import ExecutionLpRightbar from './ExecutionLpRightbar'
import ExecutionLpRuleRightbar from './ExecutionLpRuleRightbar'
import ExtApiPlatformEdit from './ExtApiPlatformEdit'
import ExtSymbolsRightbar from './ExtSymbolsRightbar'
import FeedAggregationRightbar from './FeedAggregationRightbar'
import FeedingLpRightbar from './FeedingLpRightbar'
import FeedPlatformRightbar from './FeedPlatformRightbar'
import FeedPlatformSymbolRightbar from './FeedPlatformSymbolRightbar'
import FixApiPlatformEdit from './FixApiPlatformEdit'
import FixOrdersRightbar from './FixOrdersRightbar'
import FixPositions from './FixPositions'
import FixReportsRightbar from './FixReportsRightbar'
import FixSymbolsRightbar from './FixSymbolsRightbar'
import GatewayRightbar from './GatewayRightbar'
import GlobalSymbolMapRightbar from './GlobalSymbolMapRightbar'
import ImportProcessingRulesRightbar from './ImportProcessingRulesRightbar'
import LpRightbar from './LpRightbar'
import LpSymbolMapRightbar from './LpSymbolMapRightbar'
import MarginInfoRightbar from './MarginInfoRightbar'
import MT4Deals from './MT4Deals'
import MT4PlatformEdit from './MT4PlatformEdit'
import MT4Positions from './MT4Positions'
import MT5Deals from './MT5Deals'
import MT5PlatformEdit from './MT5PlatformEdit'
import MT5Positions from './MT5Positions'
import MtExecPlatformEdit from './MtExecPlatformEdit'
import PlatformSymbolRightbar from './PlatformSymbolRightbar'
import ProcessingRuleItemRightbar from './ProcessingRuleItemRightbar'
import ProcessingRuleRightbar from './ProcessingRuleRightbar'
import RoutingRuleRightbar from './RoutingRuleRightbar'
import SessionsRightbar from './SessionsRightbar'
import SmtpRightbar from './SmtpRightbar'
import SwapsRightbar from './SwapsRightbar'
import TradingHistoryRightbar from './TradingHistoryRightbar'
import UsersRightbar from './UsersRightbar'
import VolumeTransferHistoryRightbar from './VolumeTransferHistoryRightbar'
import BinanceFuturesSwapBar from './BinanceFuturesSwapBar'

const RightbarContainer: React.FC = () => {
  const dispatch = useDispatch()
  const { rightbarHidden, name, data } = useSelector((state: any) => state.rightbar)

  let rightbarItem = <div />
  switch (name) {
    case 'mt5positions':
      rightbarItem = <MT5Positions data={data} />
      break
    case 'mt4positions':
      rightbarItem = <MT4Positions data={data} />
      break
    case 'fixapipositions':
    case 'extapipositions':
      rightbarItem = <FixPositions data={data} />
      break
    case 'mt5deals':
      rightbarItem = <MT5Deals data={data} />
      break
    case 'mt4deals':
      rightbarItem = <MT4Deals data={data} />
      break
    case 'fixReports':
      rightbarItem = <FixReportsRightbar data={data} />
      break
    case 'fixOrders':
      rightbarItem = <FixOrdersRightbar data={data} />
      break
    case 'autoSwitch':
      rightbarItem = <AutoSwitchRightbar data={data} />
      break
    case 'mt4edit':
      rightbarItem = <MT4PlatformEdit data={data} />
      break
    case 'mt5edit':
      rightbarItem = <MT5PlatformEdit data={data} />
      break
    case 'fixapiedit':
      rightbarItem = <FixApiPlatformEdit data={data} />
      break
    case 'extapiedit':
      rightbarItem = <ExtApiPlatformEdit data={data} />
      break
    case 'addNewPlatform':
      rightbarItem = <AddPlatform data={data} />
      break
    case 'executionLp':
      rightbarItem = <ExecutionLpRightbar data={data} />
      break
    case 'feedAggregation':
      rightbarItem = <FeedAggregationRightbar data={data} />
      break
    case 'feedPlatform':
      rightbarItem = <FeedPlatformRightbar data={data} />
      break
    case 'routingRule':
      rightbarItem = <RoutingRuleRightbar data={data} />
      break
    case 'processingRule':
      rightbarItem = <ProcessingRuleRightbar data={data} />
      break
    case 'aggregationPool':
      rightbarItem = <AggregationPoolRightbar data={data} />
      break
    case 'executionlp':
      rightbarItem = <ExecutionLpRightbar data={data} />
      break
    case 'sessions':
      rightbarItem = <SessionsRightbar data={data} />
      break
    case 'accounts':
      rightbarItem = <AccountsRightbar data={data} />
      break
    case 'swaps':
      rightbarItem = <SwapsRightbar data={data} />
      break
    case 'feedingLp':
      rightbarItem = <FeedingLpRightbar data={data} />
      break
    case 'gateway':
      rightbarItem = <GatewayRightbar data={data} />
      break
    case 'database':
      rightbarItem = <DatabaseRightbar data={data} />
      break
    case 'smtp':
      rightbarItem = <SmtpRightbar data={data} />
      break
    case 'sysLp':
      rightbarItem = <LpRightbar data={data} />
      break
    case 'symbolMap':
      rightbarItem = <FixSymbolsRightbar data={data} />
      break
    case 'extSymbols':
      rightbarItem = <ExtSymbolsRightbar data={data} />
      break
    case 'consolidation':
      rightbarItem = <ConsolidationRightbar data={data} />
      break
    case 'dropCopy':
      rightbarItem = <DropCopyRightbar data={data} />
      break
    case 'dropCopySettings':
      rightbarItem = <DropCopySettingsRightbar data={data} />
      break
    case 'globalSymbolMap':
      rightbarItem = <GlobalSymbolMapRightbar data={data} />
      break
    case 'lpSymbolMap':
      rightbarItem = <LpSymbolMapRightbar data={data} />
      break
    case 'feedPlatformSymbols':
      rightbarItem = <FeedPlatformSymbolRightbar data={data} />
      break
    case 'BinanceFuturesSwapBar':
      rightbarItem = <BinanceFuturesSwapBar data={data} />
      break
    case 'platformSymbols':
      rightbarItem = <PlatformSymbolRightbar data={data} />
      break
    case 'ceVolumeDistribution':
      rightbarItem = <CeVolumeDistributionRightbar data={data} />
      break
    case 'ceRule':
      rightbarItem = <CeRuleRightbar data={data} />
      break
    case 'marginInfo':
      rightbarItem = <MarginInfoRightbar data={data} />
      break
    case 'processingRuleItem':
      rightbarItem = <ProcessingRuleItemRightbar data={data} />
      break
    case 'executionLpRule':
      rightbarItem = <ExecutionLpRuleRightbar data={data} />
      break
    case 'users':
      rightbarItem = <UsersRightbar data={data} />
      break
    case 'importProcessingRules':
      rightbarItem = <ImportProcessingRulesRightbar data={data} />
      break
    case 'volumeTransferHistory':
      rightbarItem = <VolumeTransferHistoryRightbar data={data} />
      break
    case 'tradingHistory':
      rightbarItem = <TradingHistoryRightbar data={data} />
      break
    case 'mtexecedit':
      rightbarItem = <MtExecPlatformEdit data={data} />
      break
  }

  const handleClick = useCallback(() => dispatch(hideRightBar()), [dispatch])

  return <AppRightBar className="app-rightbar w-800" hidden={rightbarHidden} items={rightbarItem} handleClick={handleClick} />
}

export default React.memo(RightbarContainer)
