import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConsolidationRuleEntity } from '../../entity/consolidation-entity'
import { GatewayEntity } from '../../entity/system-settings'
import useData from '../../hooks/useData'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchConsolidationRulesUpdate } from '../../redux/actions/consolidation-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, mselectInput, sselectInput, textInput, timespanInput } from '../../utils/controls'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const ConsolidationRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ConsolidationRuleEntity(item), ConsolidationRuleEntity.schema)

  const symbols = useData('/api/consolidation/GlobalSymbols', [], {
    Name: params.Gateway,
  } as GatewayEntity)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(hideRightBar())
    dispatch(
      fetchConsolidationRulesUpdate({
        action: type,
        rule: { ...inputState, Symbol: inputState.Symbol?.value },
        params,
      }),
    )
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`consolidation-rules.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            sselectInput('Symbol', buildMultiselectOptionsFromArray(symbols)),
            mselectInput('Lps', params.Lps || []),
            timespanInput('Start'),
            timespanInput('Finish'),
            textInput('MaxSpread'),
            textInput('MaxOrderVolume'),
            textInput('Timeout'),
          ],
          inputState,
          setInputState,
          'consolidation-rules',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ConsolidationRightbar
