import React from 'react'
import { FormattedMessage } from 'react-intl'

interface IInfoItem {
  first: string
  second: JSX.Element | string | number
  variant?: string
  l?: number
  r?: number
  className?: string
}

const InfoItem: React.FC<IInfoItem> = ({ variant, l, r, first, second }) => {
  const className = variant ?? 'default'

  return (
    <div className={`row info-item ${className}`}>
      <div className={l ? `col-${l}` : 'col-6'}>
        <span className="mr-4 nowrap">{first ? <FormattedMessage id={first}>{(txt: any) => `${txt}:`}</FormattedMessage> : ''}</span>
      </div>
      <div className={r ? `col-${r}` : 'col-6'} style={{ fontSize: '13px' }}>
        {typeof second === 'string' ? <span>{second}</span> : second}
      </div>
    </div>
  )
}

export default InfoItem
