import React, { useRef, useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import {
  fetchLpsAdd,
  fetchLpsAddBased,
  fetchLpsUpdate,
  fetchLpsUpdateBased,
  fetchLpsUpdateHedgingBBook,
  fetchLpsAddHedgingBBook,
} from '../../redux/actions/system-settings-actions'
import { buildControlsExtTwoPerLine, checkboxInput, passwordInput, selectInput, textInput, timespanInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { folderNameSchema, portSchema, transformEmptyString } from '../../utils/schema-utils'
import { LpAlpaca, LpEntity, HedgingBBook, Binance, Laverate } from '../../entity/system-settings'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { Limit, LpSession } from '../../entity/platforms'
import { buildOverridesHedgingBBookTable, buildOverridesLimitTable, buildOverridesMarketTable } from './lpRightbarUtils/LpRightbarUtils'

function getExecSchema() {
  const s = () => yup.string().notRequired()
  return {
    SenderCompId: s(),
    TargetCompId: s(),
    BeginString: s(),
    Username: s(),
    Password: s(),
    ReconnectInterval: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
    FileStorePath: s(),
    FileLogPath: s(),
    SocketConnectHost: s(),
    SocketConnectPort: portSchema().notRequired(),
    MaxMessagesPerSec: yup.number().min(0).integer().transform(transformEmptyString).nullable().notRequired(),
    OnBehalfOfCompId: yup.string().matches(/^true$|^false$|^$/gi),
  }
}

function getFeedSchema() {
  const s = () => yup.string().notRequired()
  return {
    SenderCompId: s(),
    TargetCompId: s(),
    BeginString: s(),
    Username: s(),
    Password: s(),
    ReconnectInterval: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
    FileStorePath: s(),
    FileLogPath: s(),
    WriteLogs: s(),
    SocketConnectHost: s(),
    SocketConnectPort: portSchema().notRequired(),
    Server: s(),
    Login: s(),
    ResubscribeOnRejectTimeout: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
  }
}

const schema = {
  Name: folderNameSchema(),
  Type: yup.string().required(),
}

const alpacaSchema = {
  ApiKey: yup.string().required(),
  ApiSecret: yup.string().required(),
  Live: yup.boolean().required(),
}

const leverateSchema = {
  ApiKey: yup.string().required(),
  ApiSecret: yup.string().required(),
  ReconnectTimeout: yup.number().required(),
  EnableFeederLogging: yup.boolean().required(),
}

const bbookHedged = {
  Thresholds: yup.array().of(
    yup.object().shape({
      Mask: yup.string(),
      Threshold: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value),
    }),
  ),
}

const brandFieldSet = new Set(['CMC', 'PrimeFX', 'Equiti', 'Infinox', 'Amana', 'GBE', 'ADSSo', 'ADSSp', 'AlchemyMarkets', 'ScopeMarkets', 'Saxo'])

const onBehalfOfCompIdFieldSet = new Set(['Equiti', 'Infinox', 'Amana', 'GBE', 'ADSSo', 'ADSSp', 'AlchemyMarkets', 'ScopeMarkets', 'Saxo'])

const partyIdFieldSet = new Set(['HitBTC'])

const queryIntervalFieldSet = new Set(['HitBTC'])

const targetSubIDFieldSet = new Set(['FXCM', 'cTrader'])

const senderSubIdFieldSet = new Set(['IGDMA'])

const resubscribeOnRejectTimeoutFieldSet = new Set(['Exante'])

const quoteTextProcessingFieldSet = new Set(['LMAX'])

const targetBinanceFuturesFieldSet = new Set(['BinanceFutures'])

function isAlpaca(obj: any): boolean {
  return obj.Type === 'AlpacaMarkets'
}

function isBita(obj: any): boolean {
  return obj.Type === 'Bita' || obj.Type === 'DxFeed' || obj.Type === 'XValley'
}

function isLeverate(obj: any): boolean {
  return obj.Type === 'Leverate' || obj.Type === 'MorningStar'
}

function isHedgingBBook(obj: any): boolean {
  return obj.Type === 'BbookHedged'
}

function isBinance(obj: any): boolean {
  return obj.Type === 'BinanceStock' || obj.Type === 'BinanceFutures'
}

const LpRightbar: React.FC<IRightbar> = ({ data: { type, params, item } }) => {
  const dispatch = useDispatch()
  const { types, lps, pool, allowedDepthLevels, allowedQuotesUpdateIntervals, MinAllowedLeverage, MaxAllowedLeverage } = useSelector(
    (state: any) => state.sysLps,
  )
  const execRef = useRef<any>(null)
  const feedRef = useRef<any>(null)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new LpEntity(item), schema)
  const [execState, setExecState, execTouched, setExecTouched, execErrors, isExecValid] = useFormValidation(
    new LpSession(item.ExecutionSession),
    getExecSchema(),
  )

  const [feedingState, setFeedingState, feedingTouched, setFeedingTouched, feedingErrors, isFeedingValid] = useFormValidation(
    new LpSession(item.FeedingSession),
    getFeedSchema(),
  )

  const [alpacaState, setAlpacaState, alpacaTouched, setAlpacaTouched, alpacaErrors, isAlpacaValid] = useFormValidation(new LpAlpaca(item), alpacaSchema)

  const [hedgingBBookState, setHedgingBBookState, hedgingBBookTouched, setHedgingBBookTouched, hedgingBBookErrors] = useFormValidation(
    new HedgingBBook(item),
    bbookHedged,
  )

  const [leverateState, setLeverateState, leverateTouched, setLeverateTouched, leverateErrors, isLeverateValid] = useFormValidation(
    new Laverate(item),
    leverateSchema,
  )

  useEffect(() => {
    if (inputState.Type === 'MorningStar' || inputState.Type === 'Leverate') {
      setLeverateState((prev: any) => {
        return {
          ...prev,
          LogPath: inputState.Name.length === 0 ? '' : `${inputState.Name}_log`,
        }
      })
    }
  }, [inputState.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'modify') {
      return
    } else {
      if (inputState.Type === 'TradeviewPrime' || inputState.Type === 'cTrader') {
        const newLimit = [new Limit({ Symbol: '*', Mode: 'GTCwithCancel' })]
        execState.TifOverrides.Limit = newLimit
        setExecState({ ...execState })
      } else {
        const newLimit = [new Limit({ Symbol: '', Mode: '' })]
        execState.TifOverrides.Limit = newLimit
        setExecState({ ...execState })
      }
    }
  }, [inputState.Type]) // eslint-disable-line react-hooks/exhaustive-deps

  // =========

  const binanceSchema = {
    ApiKey: yup.string().required(),
    ApiSecret: yup.string().required(),
    LogPath: yup.string().required(),
    AllowedCancellationsForUser: yup.number().required(),
    AllowedCancellationsForSession: yup.number().required(),
    CancellationRatingResetTimeout: yup.number().required(),
    ReconnectTimeout: yup.number().required(),
    TasksLimit: yup.number().required(),
    DepthLevels: yup.number().required(),
    QuotesUpdateInterval: yup.number().required(),
    StreamLastPrices: yup.boolean().required(),
    Leverage: yup.number().min(MinAllowedLeverage).max(MaxAllowedLeverage).required(),
  }

  const [binanceState, setBinanceState, binanceTouched, setBinanceTouched, binanceErrors, isBinanceValid] = useFormValidation(new Binance(item), binanceSchema)

  // =========

  const handleSave = () => {
    const cond1 = isValid()
    const cond2 = isExecValid()
    const cond3 = isFeedingValid()
    const alpacaValid = isAlpacaValid()
    const binanceValid = isBinanceValid()
    const laverate = isLeverateValid()

    if (!cond2) {
      if (execRef.current) {
        execRef.current.open()
      }
    }

    if (!cond3) {
      if (feedRef.current) {
        feedRef.current.open()
      }
    }

    if (inputState.Type === 'Mt4') {
      if (!cond1) {
        return
      }
    } else if (inputState.Type === 'Bbook') {
      if (!cond1) {
        return
      }
    } else if (isAlpaca(inputState)) {
      if (!alpacaValid) {
        return
      }
    } else if (isBinance(inputState)) {
      if (!binanceValid) {
        return
      }
    } else if (isHedgingBBook(inputState)) {
      if (hedgingBBookErrors.Thresholds || errors.Name) {
        hedgingBBookState.Thresholds.forEach((item: any) => {
          if (item.Threshold === '') {
            item.Threshold = undefined
          }
        })
        return
      }
    } else if (isLeverate(inputState)) {
      if (!laverate) {
        return
      }
    } else if (!cond1 || !cond2 || !cond2) {
      return
    }
    let body = {
      ...inputState,
      FeedingSession: {
        ...feedingState,
      },
      ExecutionSession: {
        ...execState,
        TifOverrides: {
          Market: execState.TifOverrides.Market,
          Limit: execState.TifOverrides.Limit,
        },
      },
    }

    if (inputState.Type === 'Bbook') {
      body = {
        Name: inputState.Name,
        Type: inputState.Type,
      }
    } else if (inputState.Type === 'Mt4') {
      delete body.ExecutionSession
    } else if (isHedgingBBook(inputState)) {
      body = {
        Name: inputState.Name,
        TypeName: inputState.Type,
        AggregationPool: hedgingBBookState.AggregationPool === '' ? pool[0] : hedgingBBookState.AggregationPool,
        Thresholds: hedgingBBookState.Thresholds,
      }
    } else if (isAlpaca(inputState)) {
      body = {
        Name: inputState.Name,
        Type: inputState.Type,
        ApiKey: alpacaState.ApiKey,
        ApiSecret: alpacaState.ApiSecret,
        Live: alpacaState.Live,
      }
    } else if (isLeverate(inputState)) {
      body =
        inputState.Type !== 'MorningStar'
          ? {
              Name: inputState.Name,
              Type: inputState.Type,
              ApiKey: leverateState.ApiKey,
              ApiSecret: leverateState.ApiSecret,
              LogPath: leverateState.LogPath,
              ReconnectTimeout: leverateState.ReconnectTimeout,
              EnableFeederLogging: leverateState.EnableFeederLogging,
            }
          : {
              Name: inputState.Name,
              Type: inputState.Type,
              ApiKey: leverateState.ApiKey,
              ApiSecret: leverateState.ApiSecret,
              LogPath: leverateState.LogPath,
              ReconnectTimeout: leverateState.ReconnectTimeout,
              EnableFeederLogging: leverateState.EnableFeederLogging,
              WebServiceUrl: leverateState.WebServiceUrl,
            }
    } else if (isBinance(inputState)) {
      body = {
        Name: inputState.Name,
        Type: inputState.Type,
        ApiKey: binanceState.ApiKey,
        ApiSecret: binanceState.ApiSecret,
        LogPath: binanceState.LogPath,
        AllowedCancellationsForUser: binanceState.AllowedCancellationsForUser,
        AllowedCancellationsForSession: binanceState.AllowedCancellationsForSession,
        CancellationRatingResetTimeout: binanceState.CancellationRatingResetTimeout,
        ReconnectTimeout: binanceState.ReconnectTimeout,
        TasksLimit: binanceState.TasksLimit,
        DepthLevels: binanceState.DepthLevels,
        QuotesUpdateInterval: binanceState.QuotesUpdateInterval,
        StreamLastPrices: binanceState.StreamLastPrices,
        EnableFeederLogging: binanceState.EnableFeederLogging,
        Leverage: binanceState.Type === 'BinanceFutures' ? binanceState.Leverage : null,
      }
    }

    if (type === 'add') {
      if (isAlpaca(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isLeverate(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isBinance(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isHedgingBBook(inputState)) {
        dispatch(fetchLpsAddHedgingBBook({ action: '', params, body }))
      } else {
        dispatch(fetchLpsAdd({ action: type, params, body }))
      }
    } else if (type === 'modify') {
      if (isAlpaca(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isLeverate(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isBinance(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isHedgingBBook(inputState)) {
        dispatch(fetchLpsUpdateHedgingBBook({ action: '', params, body }))
      } else {
        dispatch(fetchLpsUpdate({ action: type, params, body }))
      }
    }
    dispatch(hideRightBar())
  }
  const execution = buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId'),
      textInput('TargetCompId'),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      timespanInput('StartTime'),
      timespanInput('EndTime'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      textInput('MaxMessagesPerSec'),
      textInput('Account'),
      checkboxInput('SendRedundantResendRequests'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      textInput('SslCertificate').disabled(!execState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!execState.SslEnable)
        .holder('optional'),

      textInput('SslCaCertificate').disabled(!execState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!execState.SslEnable).holder('optional'),
      textInput('Brand').skipWhen(!brandFieldSet.has(inputState.Type)),
      textInput('OnBehalfOfCompId').skipWhen(!onBehalfOfCompIdFieldSet.has(inputState.Type)),
      textInput('PartyId').skipWhen(!partyIdFieldSet.has(inputState.Type)),
      textInput('SenderSubId').skipWhen(!senderSubIdFieldSet.has(inputState.Type)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type)),
    ],
    execState,
    setExecState,
    'lp',
    execTouched,
    setExecTouched,
    execErrors,
  )

  const feeding = buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId'),
      textInput('TargetCompId'),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      timespanInput('StartTime'),
      timespanInput('EndTime'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      textInput('SslCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!feedingState.SslEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!feedingState.SslEnable).holder('optional'),
      textInput('QueryInterval').skipWhen(!queryIntervalFieldSet.has(inputState.Type)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type)),
      textInput('ResubscribeOnRejectTimeout').skipWhen(!resubscribeOnRejectTimeoutFieldSet.has(inputState.Type)),
      checkboxInput('QuoteTextProcessing').skipWhen(!quoteTextProcessingFieldSet.has(inputState.Type)),
    ],
    feedingState,
    setFeedingState,
    'lp',
    feedingTouched,
    setFeedingTouched,
    feedingErrors,
  )

  const alpacaInputs = buildControlsExtTwoPerLine(
    [textInput('ApiKey'), textInput('ApiSecret'), checkboxInput('Live')],
    alpacaState,
    setAlpacaState,
    'lp',
    alpacaTouched,
    setAlpacaTouched,
    alpacaErrors,
  )

  const binanceInputs = buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      textInput('ApiSecret'),
      selectInput('DepthLevels', allowedDepthLevels),
      selectInput('QuotesUpdateInterval', allowedQuotesUpdateIntervals),
      textInput('Leverage').skipWhen(!targetBinanceFuturesFieldSet.has(inputState.Type)),
      checkboxInput('StreamLastPrices'),
    ],
    binanceState,
    setBinanceState,
    'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )

  const binanceInputsAccordion = buildControlsExtTwoPerLine(
    [
      textInput('AllowedCancellationsForUser'),
      textInput('AllowedCancellationsForSession'),
      textInput('ReconnectTimeout'),
      textInput('CancellationRatingResetTimeout'),
      textInput('LogPath'),
      textInput('TasksLimit'),
      checkboxInput('EnableFeederLogging'),
    ],
    binanceState,
    setBinanceState,
    'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )

  const hedgingBBookPool = buildControlsExtTwoPerLine(
    [selectInput('AggregationPool').optionItems(pool)],
    hedgingBBookState,
    setHedgingBBookState,
    'lp',
    hedgingBBookTouched,
    setHedgingBBookTouched,
    errors,
  )

  const leverate = buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      textInput('ApiSecret'),
      textInput('LogPath').disabled(!leverateState.EnableFeederLogging),
      textInput('ReconnectTimeout'),
      checkboxInput('EnableFeederLogging'),
      textInput('WebServiceUrl').skipWhen(inputState.Type !== 'MorningStar'),
    ],
    leverateState,
    setLeverateState,
    'leverate',
    leverateTouched,
    setLeverateTouched,
    leverateErrors,
  )

  let controls = null
  if (inputState.Type === 'Mt4') {
    controls = (
      <Card style={{ margin: '0 -20px' }}>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id="lp.Feeding" />
        </Card.Header>
        <Card.Body>
          {buildControlsExtTwoPerLine(
            [textInput('Server'), textInput('Login'), textInput('Password')],
            feedingState,
            setFeedingState,
            'lp',
            feedingTouched,
            setFeedingTouched,
            feedingErrors,
          )}
        </Card.Body>
      </Card>
    )
  } else if (inputState.Type === 'Bbook') {
    controls = null
  } else if (isAlpaca(inputState)) {
    controls = alpacaInputs
  } else if (isLeverate(inputState)) {
    controls = leverate
  } else if (isBita(inputState)) {
    controls = <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
  } else if (isBinance(inputState)) {
    controls = (
      <div>
        <div>{binanceInputs}</div>

        <AppAccordion
          item={{
            title: <FormattedMessage id={'Lp.Advanced'} />,
            item: binanceInputsAccordion,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={true}
        />
      </div>
    )
  } else if (isHedgingBBook(inputState)) {
    controls = (
      <div>
        <div>{hedgingBBookPool}</div>

        <AppAccordion
          item={{
            title: <FormattedMessage id={'Lp.BbookHedged'} />,
            item: buildOverridesHedgingBBookTable(hedgingBBookState, hedgingBBookErrors, hedgingBBookTouched, setHedgingBBookTouched, setExecState, execState),
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />
      </div>
    )
  } else {
    controls = (
      <>
        <AppAccordion
          item={{
            title: <FormattedMessage id="lp.Execution" />,
            item: execution,
          }}
          ref={execRef}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
        <AppAccordion
          item={{
            title: <FormattedMessage id={'LPR.Overrides'} />,
            item: [buildOverridesMarketTable(execState, setExecState), buildOverridesLimitTable(inputState, setExecState, execState)],
          }}
          style={{ margin: '0 -20px' }}
        />
      </>
    )
  }

  const setName = (newState: any) => {
    const partialState = {
      FileStorePath: (newState.Name && newState.Name + '_store') || '',
      FileLogPath: (newState.Name && newState.Name + '_log') || '',
    }
    const binanceNewState = {
      LogPath: (newState.Name && newState.Name + '_log') || ' ',
    }

    setInputState(newState)
    setExecState({ ...execState, ...partialState })
    setFeedingState({ ...feedingState, ...partialState })
    setBinanceState({ ...binanceState, ...binanceNewState })
  }

  const setType = (newState: any) => {
    if (newState.Type === 'IG') {
      setExecState({ ...execState, BeginString: 'FIXT.1.1' })
      setFeedingState({ ...feedingState, BeginString: 'FIXT.1.1' })
    } else if (newState.Type === 'IGDMA') {
      setExecState({ ...execState, BeginString: 'FIX.4.2' })
      setFeedingState({ ...feedingState, BeginString: 'FIXT.1.1' })
    } else if (newState.Type === 'CubicFX') {
      setExecState({ ...execState, BeginString: 'FIX.4.3' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.3' })
    } else if (newState.Type === 'HantecMarkets') {
      setExecState({ ...execState, BeginString: 'FIX.4.3' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.3' })
    } else {
      setExecState({ ...execState, BeginString: 'FIX.4.4' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.4' })
    }
    setInputState(newState)
  }

  const alreadyExist = () => {
    if (type === 'add' && lps.find((lp: LpEntity) => lp.Name === inputState.Name)) {
      errors.Name = true
      return 'lp.exists'
    }
    return ''
  }

  const titleLP = type !== 'modify' ? 'lp.Title' : 'lp.modifyTitle'
  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={titleLP} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist())
              .stateSetup(setName),
            selectInput('Type')
              .optionItems(types)
              .disabled(type !== 'add'),
          ],
          inputState,
          setType,
          'lp',
          touched,
          setTouched,
          errors,
        )}
        {controls}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default LpRightbar
