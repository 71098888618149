import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { IRightbar } from './rightbar-types'

const schema = {
  PlatformSymbol: yup.string().required(),
  GatewaySymbol: yup.string().required(),
  Digits: yup.number().required(),
  VolumeStep: yup.number().required(),
}

const PlatformSymbolRightbar: React.FC<IRightbar> = ({ data: { item, options, onChange } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (onChange) {
      onChange(inputState)
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="platform-symbol-settings.title" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            sselectInput('PlatformSymbol', options).disabled(true),
            sselectInput('GatewaySymbol', options),
            textInput('Digits'),
            textInput('VolumeStep'),
            textInput('ContractSize'),
          ],
          inputState,
          setInputState,
          'platform.symbol-map',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default PlatformSymbolRightbar
