import { FeedAgregationEntity, FeedPlatformEntity, FeedPlatformRuleEntity, LiquidityProvider } from '../../entity/feeding'
import { Action } from '../actions/actions'

interface IState {
  noChanges: boolean
  lp: LiquidityProvider[]
  feedAgregations: FeedAgregationEntity[]
  feedPlatforms: FeedPlatformEntity[]
  symbols: {
    data: FeedPlatformRuleEntity[]
    totalCount: number
  }
  highlightAction: string
  from: string
  sourceName: string
}

const INITIAL_STATE: IState = {
  noChanges: true,
  lp: [],
  feedAgregations: [],
  feedPlatforms: [],
  symbols: {
    data: [],
    totalCount: 0,
  },
  highlightAction: '',
  from: '',
  sourceName: '',
}

function feedingConfigurationReducer(state = INITIAL_STATE, action: any): IState {
  switch (action.type) {
    case Action.FeedingConfigurationInitial:
      return {
        ...state,
        noChanges: true,
        highlightAction: '',
        from: '',
        sourceName: '',
      }
    case Action.FeedingConfigurationChanged:
      return {
        ...state,
        noChanges: false,
      }

    case Action.GotLP:
      return {
        ...state,
        lp: action.data.map((item: any) => new LiquidityProvider(item)),
      }
    case Action.ChangeFeedingLp:
      return {
        ...state,
        lp: state.lp.map((item: LiquidityProvider) => {
          if (item.Id === action.data.Id) {
            return new LiquidityProvider(action.data)
          }
          return item
        }),
      }
    case Action.DeleteFeedingLp:
      return {
        ...state,
        lp: state.lp.filter((item: LiquidityProvider) => item.Id !== action.data.Id),
      }
    case Action.AddFeedingLp:
      return {
        ...state,
        lp: [...state.lp, new LiquidityProvider(action.data)],
      }

    case Action.GotFeedAgregation:
      return {
        ...state,
        feedAgregations: action.data.map((item: any) => new FeedAgregationEntity(item)),
      }
    case Action.ChangeFeedAggregation:
      return {
        ...state,
        feedAgregations: state.feedAgregations.map((item: FeedAgregationEntity) => {
          if (item.Id === action.data.Id) {
            return new FeedAgregationEntity(action.data)
          }
          return item
        }),
      }
    case Action.DeleteFeedAggregation:
      return {
        ...state,
        feedAgregations: state.feedAgregations.filter((item: FeedAgregationEntity) => item.Id !== action.data.Id),
      }
    case Action.AddFeedAggregation:
      return {
        ...state,
        feedAgregations: [
          ...state.feedAgregations,
          new FeedAgregationEntity({
            ...action.data,
            Id: -1 + Math.min(0, ...state.feedAgregations.map((item: any) => item.Id)),
          }),
        ],
      }

    case Action.GotFeedPlatforms:
      return {
        ...state,
        feedPlatforms: action.data.map((item: any) => new FeedPlatformEntity(item)),
      }
    case Action.ChangeFeedPlatform:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((item: FeedPlatformEntity) => {
          if (item.Id === action.data.Id) {
            return new FeedPlatformEntity(action.data)
          }
          return item
        }),
      }
    case Action.DeleteFeedPlatform:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.filter((item: FeedPlatformEntity) => item.Id !== action.data.Id),
      }
    case Action.AddFeedPlatform:
      return {
        ...state,
        feedPlatforms: [
          ...state.feedPlatforms,
          new FeedPlatformEntity({
            ...action.data,
            Id: -1 + Math.min(0, ...state.feedPlatforms.map((item: any) => item.Id)),
          }),
        ],
      }
    case Action.AddFeedPlatformSymbol:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((platform: FeedPlatformEntity) => {
          if (platform.Name === action.data.params.Platform) {
            const newId = -1 + Math.min(0, ...platform.Rules.map((symbol: any) => symbol.Id))
            const updatedPlatform = {
              ...platform,
              changed: true,
              Rules: [...platform.Rules, new FeedPlatformRuleEntity({ ...action.data.body, Id: newId })],
            }
            return new FeedPlatformEntity(updatedPlatform)
          }
          return platform
        }),
      }
    case Action.DeleteFeedPlatformSymbol:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((platform: FeedPlatformEntity) => {
          if (platform.Name === action.data.params.Platform) {
            return new FeedPlatformEntity({
              ...platform,
              changed: true,
              Rules: platform.Rules.filter((symbol: any) => symbol.Id !== action.data.body.Id),
            })
          }
          return platform
        }),
      }
    case Action.DeleteFeedPlatformSymbols:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((platform: FeedPlatformEntity) => {
          if (platform.Name === action.data.params.Platform) {
            return new FeedPlatformEntity({
              ...platform,
              changed: true,
              Rules: [],
            })
          }
          return platform
        }),
      }
    case Action.ChangeFeedPlatformSymbol:
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((platform: FeedPlatformEntity) => {
          if (platform.Name === action.data.params.Platform) {
            return new FeedPlatformEntity({
              ...platform,
              changed: true,
              Rules: platform.Rules.map((rule: FeedPlatformRuleEntity) => {
                if (rule.Id === action.data.body.Id) {
                  return new FeedPlatformRuleEntity(action.data.body)
                }
                return rule
              }),
            })
          }
          return platform
        }),
      }
    case Action.GotFeedPlatformSymbols: {
      const platform = state.feedPlatforms.find((item: FeedPlatformEntity) => item.Name === action.data.Platform)
      if (platform) {
        const arr = platform.Rules.slice((action.data.Page - 1) * action.data.Count, action.data.Page * action.data.Count)
        if (action.data.field && action.data.by !== 'none') {
          arr.sort((a: FeedPlatformRuleEntity, b: FeedPlatformRuleEntity) => {
            let x = a[action.data.field]
            let y = b[action.data.field]
            if (action.data.field !== 'MarkupType') {
              x = parseFloat(x)
              y = parseFloat(y)
            }
            if (x > y) {
              return action.data.by === 'asc' ? 1 : -1
            }
            if (x === y) {
              return 0
            }
            if (x < y) {
              return action.data.by === 'asc' ? -1 : 1
            }
            return 0
          })
        }
        return {
          ...state,
          symbols: {
            totalCount: platform.Rules.length,
            data: arr,
          },
        }
      }
      return state
    }
    case Action.ChangePriorityFeedPlatformSymbols: {
      const { result, pagination, params } = action.data
      result.source.index += (pagination.Page - 1) * pagination.Count
      result.destination.index += (pagination.Page - 1) * pagination.Count
      return {
        ...state,
        feedPlatforms: state.feedPlatforms.map((platform: FeedPlatformEntity) => {
          if (platform.Name === params.Platform) {
            const newSymbolArray = Array.from(platform.Rules)
            newSymbolArray.splice(result.source.index, 1)
            newSymbolArray.splice(result.destination.index, 0, platform.Rules[result.source.index])
            return new FeedPlatformEntity({
              ...platform,
              changed: true,
              Rules: newSymbolArray,
            })
          }
          return platform
        }),
      }
    }
    case Action.FeedingHighlightRelevant:
      if (action.data.action === 'highlight') {
        if (state.highlightAction === '') {
          return highlight(state, action)
        }

        if (state.highlightAction === 'highlight' && isSameSource(state, action)) {
          return unhighlight(state)
        }
      }

      if (action.data.action === 'hold') {
        if (state.highlightAction === 'highlight' && isSameSource(state, action)) {
          return highlight(state, action)
        }

        if (state.highlightAction === 'hold') {
          if (isSameSource(state, action)) {
            return {
              ...state,
              highlightAction: 'highlight',
            }
          } else {
            return highlight(state, action)
          }
        }

        if (state.highlightAction === 'hide') {
          return highlight(state, action)
        }
      }

      if (action.data.action === 'hide') {
        if (state.highlightAction === '') {
          return highlight(state, action)
        }

        if (state.highlightAction === 'hide') {
          if (isSameSource(state, action)) {
            return unhighlight(state)
          } else {
            return highlight(state, action)
          }
        }

        if (state.highlightAction === 'hold') {
          return highlight(state, action)
        }
      }

      return state
    default:
      return state
  }
}

function highlight(state: IState, action: any): IState {
  if (action.data.from === 'feedPlatforms') {
    const feedPlatformsLinks: { [index: string]: any } = {}
    const feedAggregationsLinks: { [index: string]: any } = {}
    return {
      ...state,
      feedPlatforms: fromFeedPlatforms(state.feedPlatforms, action.data.item, feedPlatformsLinks),
      feedAgregations: fromFeedAggregations(state.feedAgregations, feedPlatformsLinks, feedAggregationsLinks),
      lp: fromLp(state.lp, feedAggregationsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }
  if (action.data.from === 'feedAggregations') {
    const feedPlatformsLinks: { [index: string]: any } = { [action.data.item.Id]: true }
    const feedAggregationsLinks: { [index: string]: any } = {}
    return {
      ...state,
      feedPlatforms: toFeedAggregation(state.feedPlatforms, feedPlatformsLinks),
      feedAgregations: fromFeedAggregations(state.feedAgregations, feedPlatformsLinks, feedAggregationsLinks),
      lp: fromLp(state.lp, feedAggregationsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }
  if (action.data.from === 'feedingLps') {
    const feedPlatformsLinks: { [index: string]: any } = {}
    const feedAggregationsLinks: { [index: string]: any } = { [action.data.item.Id]: true }
    return {
      ...state,
      lp: fromLp(state.lp, feedAggregationsLinks),
      feedAgregations: toLp(state.feedAgregations, feedAggregationsLinks, feedPlatformsLinks),
      feedPlatforms: toFeedAggregation(state.feedPlatforms, feedPlatformsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }
  return state
}

function unhighlight(state: IState): IState {
  return {
    ...state,
    feedPlatforms: state.feedPlatforms.map(
      (item: FeedPlatformEntity) =>
        new FeedPlatformEntity({
          ...item,
          highlighted: false,
        }),
    ),
    feedAgregations: state.feedAgregations.map(
      (item: FeedAgregationEntity) =>
        new FeedAgregationEntity({
          ...item,
          highlighted: false,
        }),
    ),
    lp: state.lp.map((item: LiquidityProvider) => new LiquidityProvider({ ...item, highlighted: false })),
    highlightAction: '',
    from: '',
    sourceName: '',
  }
}

function isSameSource(state: IState, action: any): boolean {
  return state.from === action.data.from && state.sourceName === action.data.item.Name
}

function fromFeedPlatforms(feedPlatforms: FeedPlatformEntity[], root: FeedPlatformEntity, linkTo: any): FeedPlatformEntity[] {
  return feedPlatforms.map((item: FeedPlatformEntity) => {
    if (item.Name === root.Name) {
      linkTo[root.AggregatedFeeder] = true
      return new FeedPlatformEntity({ ...item, highlighted: true })
    }
    item.highlighted = false
    return item
  })
}

function fromFeedAggregations(aggregationPools: FeedAgregationEntity[], root: any, linkTo: any): FeedAgregationEntity[] {
  return aggregationPools.map((item: FeedAgregationEntity) => {
    if (root[item.Id]) {
      for (const feeder of item.Feeders) {
        linkTo[feeder] = true
      }
      return new FeedAgregationEntity({ ...item, highlighted: true })
    }
    item.highlighted = false
    return item
  })
}

function fromLp(lps: LiquidityProvider[], root: any): LiquidityProvider[] {
  return lps.map((item: LiquidityProvider) => {
    if (root[item.Id]) {
      return new LiquidityProvider({ ...item, highlighted: true })
    }
    item.highlighted = false
    return item
  })
}

function toFeedAggregation(feedPlatfroms: FeedPlatformEntity[], roots: any): FeedPlatformEntity[] {
  return feedPlatfroms.map((item: FeedPlatformEntity) => {
    if (roots[item.AggregatedFeeder]) {
      return new FeedPlatformEntity({ ...item, highlighted: true })
    }
    item.highlighted = false
    return item
  })
}

function toLp(feedAggregations: FeedAgregationEntity[], roots: any, links: any): FeedAgregationEntity[] {
  return feedAggregations.map((item: FeedAgregationEntity) => {
    for (const feeder of item.Feeders) {
      if (roots[feeder]) {
        links[item.Id] = true
        return new FeedAgregationEntity({ ...item, highlighted: true })
      }
    }
    item.highlighted = false
    return item
  })
}

export default feedingConfigurationReducer
