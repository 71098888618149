import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { PlatformType, Session } from '../../entity/platforms'
import { GatewayEntity } from '../../entity/system-settings'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchPlatformSessionsUpdate, onlyFetchPlatformAccountsNames, onlyFetchPlatformGroupsNames } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, cmselectInput, selectInput, textInput } from '../../utils/controls'
import { processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, optionsToStrings } from '../../utils/multiselect-utils'

interface ISessionsRightbar extends IGatewayProp {
  data: any
}

function getSessionSchema(gateway: GatewayEntity, params: { Platform: string; Type: 'string' }) {
  if (gateway && params) {
    const platform = gateway.Platforms.find(item => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mt4) {
      return Session.schemaMt4
    }

    if (platform && platform.Type === PlatformType.fixapi) {
      return Session.schemaFixApi
    }

    if (platform && platform.Type === PlatformType.extapi) {
      return Session.schemaExtApi
    }
  }
  return Session.schema
}

function isExtApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.extapi) {
      return true
    }
  }
  return false
}

function isMT4ApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mt4) {
      return true
    }
  }
  return false
}

function isFixApiPlatform(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.fixapi) {
      return true
    }
  }
  return false
}

function coverage(gateway: any, params: any): boolean {
  if (gateway && params) {
    const platform = gateway.Platforms.find((item: any) => item.Name === params.Platform)
    if (platform && platform.Type === PlatformType.mtexec) {
      return true
    }
  }
  return false
}

const SessionsRightbar: React.FC<ISessionsRightbar> = React.memo(({ data: { type, item, params }, gateway }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, getSessionSchema(gateway, params))
  const [accounts, setAccounts] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (isFixApiPlatform(gateway, params)) {
      onlyFetchPlatformAccountsNames({ ...params })
        .then((data: any) => setAccounts(buildMultiselectOptionsFromArray(data)))
        .catch((error: Error) => processError(error, dispatch))
      onlyFetchPlatformGroupsNames({ ...params })
        .then((data: any) => setGroups(buildMultiselectOptionsFromArray(data)))
        .catch((error: Error) => processError(error, dispatch))
    }
  }, [params, gateway, dispatch])
  const t4bProtocol = inputState.T4bFixProtocolVersion === 'Fix32' ? ['FIXAPI 3.2', 'FIXAPI 3.1'] : ['FIXAPI 3.1', 'FIXAPI 3.2']
  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId').disabled(type === 'modify'),
      textInput('TargetCompId').disabled(type === 'modify'),
      textInput('BeginString').disabled(type === 'modify'),
      textInput('Username'),
      textInput('Password'),
      textInput('DefaultAccount').skipWhen(isExtApiPlatform(gateway, params)).skipWhen(isMT4ApiPlatform(gateway, params)),
      selectInput('T4bFixProtocolVersion').optionItems(t4bProtocol).skipWhen(isMT4ApiPlatform(gateway, params)),
      cmselectInput('Accounts', accounts)
        .skipWhen(isExtApiPlatform(gateway, params))
        .skipWhen(isMT4ApiPlatform(gateway, params))
        .skipWhen(coverage(gateway, params)),
      cmselectInput('Groups', groups)
        .skipWhen(isExtApiPlatform(gateway, params))
        .skipWhen(isMT4ApiPlatform(gateway, params))
        .skipWhen(coverage(gateway, params)),
    ],
    inputState,
    setInputState,
    coverage(gateway, params) ? 'coverage.sessions' : 'sessions',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    const body = { ...inputState }
    body.Accounts = optionsToStrings(body.Accounts)
    body.Groups = optionsToStrings(body.Groups)

    dispatch(
      fetchPlatformSessionsUpdate({
        action: type,
        params: { ...params },
        body: {
          ...body,
          T4bFixProtocolVersion: inputState.T4bFixProtocolVersion === 'FIXAPI 3.2' || inputState.T4bFixProtocolVersion === 'Fix32' ? 'Fix32' : 'Fix31',
        },
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`sessions.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default withGateway(SessionsRightbar)
