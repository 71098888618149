import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ExecutionLpBRule, OrderSide, OrderType, PriceOptionTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addExecutionLpRule, changeExecutionLpRule, executionConfigurationChanged, getExecutionLpRules } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, mselectInput, selectInput, textInput, roundTypesConverter } from '../../utils/controls'
import { buildMultiselectOptionsFromEnum } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'
import { useTranslate } from '../../hooks/useTranslate'

const ExecutionLpRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ExecutionLpBRule(item), ExecutionLpBRule.schema)
  const translate = useTranslate()

  const { gateway } = useSelector((state: RootState) => state.gateways)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    const rule = new ExecutionLpBRule(inputState)
    if (type === 'add' || type === 'clone') {
      dispatch(addExecutionLpRule({ rule, params }))
    } else {
      dispatch(changeExecutionLpRule({ rule, params }))
    }
    dispatch(getExecutionLpRules({ ...params }))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Symbols'),
            textInput('Logins'),
            textInput('Groups'),
            textInput('MinVolume'),
            textInput('MaxVolume'),
            textInput('Security'),
            mselectInput('OrderTypes', buildMultiselectOptionsFromEnum(OrderType)),
            selectInput('OrderSides').optionItems(Object.keys(OrderSide)),
            blankInput(),

            textInput('DelayFrom'),
            textInput('DelayTo'),
            textInput('TicksThreshold'),
            checkboxInput('ConsiderConnectorSpread'),
            selectInput('PriceOption')
              .optionItems(Object.values(PriceOptionTypes))
              .converter(roundTypesConverter(translate))
              .skipWhen(!gateway.EnablePriceOption),
            textInput('WorstPricePercentage').skipWhen(inputState.PriceOption !== PriceOptionTypes.WORST_PERCENTAGE_PRICE || !gateway.EnablePriceOption),
            checkboxInput('Enabled'),
          ],
          inputState,
          setInputState,
          'execution-lp',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ExecutionLpRuleRightbar
