import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface ITextInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  flag?: boolean

  setState(state: any): any

  setTouched?(touched: any): any
}

const TextInput: React.FC<ITextInput> = props => {
  const { state, setState, touched, setTouched, errors, errorText, name, className, placeholder, label, isDisabled, flag } = props

  let validThreshold = !(
    name === 'Threshold' &&
    +state.Threshold >= 0 &&
    String(state.Threshold).indexOf(',') === -1 &&
    String(state.Threshold).indexOf('e') === -1
  )

  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  return (
    <Form.Group className={className}>
      {label ? (
        <>
          <Form.Label>
            <FormattedMessage id={label} tagName="span" />
          </Form.Label>
          <span>:</span>
        </>
      ) : null}
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={state[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!!isDisabled}
        isInvalid={(flag ? validThreshold : errors) && (flag ? true : touched) && (flag ? true : touched[name]) && errors && (flag ? `${name}s` : errors[name])}
      />
      <Form.Control.Feedback type="invalid">
        <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default React.memo(TextInput)
