import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { commissionTypes, OrderSide, OrderType, ProcessingRuleItem, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { useTranslate } from '../../hooks/useTranslate'
import {
  addProcessingRuleItem,
  changeProcessingRuleItem,
  executionConfigurationChanged,
  getProcessingRuleItems,
} from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import {
  blankInput,
  buildControlsExtTwoPerLine,
  checkboxInput,
  commissionTypesConverter,
  mselectInput,
  roundTypesConverter,
  selectInput,
  textInput,
} from '../../utils/controls'
import { buildMultiselectOptionsFromEnum } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const ProcessingRuleItemRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ProcessingRuleItem(item), ProcessingRuleItem.schema)
  const { gateway } = useSelector((state: RootState) => state.gateways)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    const rule = new ProcessingRuleItem(inputState)
    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRuleItem({ rule, params }))
    } else {
      dispatch(changeProcessingRuleItem({ rule, params }))
    }
    dispatch(getProcessingRuleItems({ ...params }))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Symbols'),
            textInput('Logins'),
            textInput('Groups'),
            textInput('MinVolume'),
            textInput('MaxVolume'),
            textInput('Security'),
            mselectInput('OrderTypes', buildMultiselectOptionsFromEnum(OrderType)),
            selectInput('OrderSides').optionItems(Object.keys(OrderSide)),
            blankInput(),
            textInput('MarkupFrom'),
            textInput('MarkupTo'),
            textInput('LimitMarkupFrom'),
            textInput('LimitMarkupTo'),
            selectInput('RoundType').optionItems(roundTypes).converter(roundTypesConverter(translate)),
            checkboxInput('TakePositiveSlippage').skipWhen(!gateway.EnablePriceOption),
            textInput('Commission'),
            selectInput('CommissionType').optionItems(commissionTypes).converter(commissionTypesConverter(translate)),
            textInput('DelayFrom'),
            textInput('DelayTo'),
            checkboxInput('AllowContinuousExecutionForFOK'),
            checkboxInput('Enabled'),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleItemRightbar
