import { LpAlpaca, LpEntity, LpSymbol, HedgingBBook, Binance, Laverate } from '../../entity/system-settings'
import { Action } from '../actions/actions'

class SysLpsState {
  lps: LpEntity[]
  alpacaLps: LpAlpaca[]
  types: string[]
  symbolMap: any
  hedgingBBook: any[]
  ApiBasedLps: any[]

  constructor() {
    this.lps = []
    this.alpacaLps = []
    this.types = []
    this.symbolMap = {
      data: [],
      totalCount: 0,
    }
    this.hedgingBBook = []
    this.ApiBasedLps = []
  }
}

function sysLpsReducer(state = new SysLpsState(), action: any) {
  switch (action.type) {
    case Action.GotSysLps:
      return {
        ...state,
        lps: action.data.GenericLps.map((item: any) => new LpEntity(item)),
        ApiBasedLps: action.data.ApiBasedLps.map((item: any) => {
          if (item.Type === 'AlpacaMarkets') {
            return new LpAlpaca(item)
          } else if (item.Type === 'Laverate') {
            return new Laverate(item)
          } else {
            return new Binance(item)
          }
        }),
        hedgingBBook: action.data.BBooksHedged.map((item: any) => new HedgingBBook(item)),
      }
    case Action.GotSysLpSymbolMap:
      return {
        ...state,
        symbolMap: {
          data: action.data.Data.map((item: any) => new LpSymbol(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotSysLpBinanceSelect:
      return {
        ...state,
        allowedDepthLevels: action.data.AllowedDepthLevels,
        allowedQuotesUpdateIntervals: action.data.AllowedQuotesUpdateIntervals,
        MinAllowedLeverage: action.data.MinAllowedLeverage,
        MaxAllowedLeverage: action.data.MaxAllowedLeverage,
      }
    case Action.GotLPTypes:
      return {
        ...state,
        types: action.data,
      }
    case Action.GotLPPool:
      return {
        ...state,
        pool: action.data,
      }
    default:
      return state
  }
}

export default sysLpsReducer
