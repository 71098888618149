import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { modifyGlobalSymbolMap } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, optionsToStrings } from '../../utils/multiselect-utils'
import { buildTableHeadersFromArray } from '../../utils/table-utils'
import MultiSelectInput from '../inputs/MultiSelectInput'
import SelectInput from '../inputs/SelectInput'
import TextInput from '../inputs/TextInput'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const GlobalSymbolMapRightbar: React.FC<IRightbar> = props => {
  const dispatch = useDispatch()
  const { type, item, params } = props.data
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    { Symbol: item.Symbol, Description: item.Description },
    { Symbol: yup.string().required() },
  )
  const { gateway } = useSelector((state: any) => state.gateways)

  useEffect(() => {
    if (gateway.Name) {
      const newState = {
        Gateway: gateway.Name,
      }

      const url = new URL('/api/globalSymbolMap/symbols', document.location.origin)
      url.searchParams.set('gateway', gateway.Name)

      fetch(url.toString(), buildHTTPGetOptions())
        .then((response: Response) => checkResponse(response))
        .then((data: any) => {
          const lpsData: any = {}
          data.Lps.forEach((lp: any) => {
            lpsData[lp.Name] = lp.Symbols
          })

          const platformsData: any = {}
          data.Platforms.forEach((platform: any) => {
            platformsData[platform.Name] = buildMultiselectOptionsFromArray(platform.Symbols)
          })

          setInputState({
            ...inputState,
            ...newState,
            PlatformsData: platformsData,
            LpsData: lpsData,
          })
        })
        .catch((error: Error) => processError(error, dispatch))
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  const [lpState, setLpState] = useState(item.LpSymbols)

  const buildLpTable = () => {
    const handlePlus = () => {
      const lpName = Object.keys(inputState.LpsData)[0] || ''
      let symbol = ''
      if (lpName) {
        symbol = (inputState.LpsData[lpName] && inputState.LpsData[lpName][0]) || ''
      }
      setLpState([...lpState, { Lp: lpName, Symbol: symbol }])
    }

    const lpRows = lpState.map((elem: any, index: number) => {
      const handleDelete = () => {
        const newState = [...lpState]
        newState.splice(index, 1)
        setLpState(newState)
      }

      const handleStateChange = (newState: any) => {
        const newStateArr = [...lpState]
        newStateArr.splice(index, 1, newState)
        setLpState(newStateArr)
      }
      return (
        <tr key={index}>
          <td>
            <SelectInput state={lpState[index]} setState={handleStateChange} name="Lp" options={Object.keys(inputState.LpsData || {})} className="m-0" />
          </td>
          <td>
            <SelectInput
              state={lpState[index]}
              setState={handleStateChange}
              name="Symbol"
              options={(inputState.LpsData && inputState.LpsData[elem.Lp]) || []}
              className="m-0"
            />
          </td>
          <td className="h-100">
            <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </tr>
      )
    })

    lpRows.push(
      <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
        <td colSpan={3}>
          <FontAwesomeIcon icon={faPlus} />
        </td>
      </tr>,
    )

    const header = buildTableHeadersFromArray(
      ['Lp', 'Symbol', 'Actions'].map((elem: string) => ({
        name: elem,
        show: true,
      })),
      'global-symbol-map',
    )

    const tableLps = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
    return tableLps
  }

  const [platformState, setPlatformState] = useState(
    item.PlatformSymbols.map((platform: any) => {
      return {
        ...platform,
        Symbol: buildMultiselectOptionsFromArray(platform.Symbol),
      }
    }),
  )

  const handlePlus = () => {
    setPlatformState([
      ...platformState,
      {
        Platform: Object.keys(inputState.PlatformsData || {})[0] || '',
        Symbol: '',
      },
    ])
  }

  const platformRows = platformState.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...platformState]
      newState.splice(index, 1)
      setPlatformState(newState)
    }

    const handleStateChange = (newState: any) => {
      const newStateArr = [...platformState]
      newStateArr.splice(index, 1, newState)
      setPlatformState(newStateArr)
    }

    const handlePlatformChange = (newState: any) => {
      const newStateArr = [...platformState]
      newStateArr.splice(index, 1, { ...newState, Symbol: [] })
      setPlatformState(newStateArr)
    }

    return (
      <tr key={index}>
        <td>
          <SelectInput
            state={platformState[index]}
            setState={handlePlatformChange}
            name="Platform"
            options={Object.keys(inputState.PlatformsData || {})}
            className="m-0"
            style={{ minWidth: '225px' }}
          />
        </td>
        <td>
          <MultiSelectInput
            state={platformState[index]}
            setState={handleStateChange}
            name="Symbol"
            options={inputState.PlatformsData && inputState.PlatformsData[elem.Platform]}
            className="m-0"
          />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  platformRows.push(
    <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
      <td colSpan={3}>
        <FontAwesomeIcon icon={faPlus} />
      </td>
    </tr>,
  )

  const header = buildTableHeadersFromArray(
    ['Platform', 'PlatformSymbols', 'Actions'].map((elem: string) => ({
      name: elem,
      show: true,
    })),
    'global-symbol-map',
  )

  const tablePlatforms = <AppTable tableHeader={header} tableData={platformRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      modifyGlobalSymbolMap({
        action: type,
        params: { ...params },
        body: {
          ...item,
          Symbol: inputState.Symbol,
          Description: inputState.Description,
          LpSymbols: lpState,
          PlatformSymbols: platformState.map((platform: any) => ({
            ...platform,
            Symbol: optionsToStrings(platform.Symbol),
          })),
        },
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`global-symbol-map.${type}`} />
      </Card.Header>
      <Card.Body>
        <TextInput
          state={inputState}
          setState={setInputState}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="Symbol"
          label="global-symbol-map.Symbol"
        />

        <TextInput
          state={inputState}
          setState={setInputState}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="Description"
          label="global-symbol-map.Description"
        />

        <AppAccordion
          item={{
            title: <FormattedMessage id={`global-symbol-map.lp.${type}`} />,
            item: buildLpTable(),
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />

        <AppAccordion
          item={{
            title: <FormattedMessage id={`global-symbol-map.platform.${type}`} />,
            item: tablePlatforms,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default GlobalSymbolMapRightbar
