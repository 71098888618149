import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useTranslate } from '../../hooks/useTranslate'
import '../../App.scss'

interface ISelectItems {
  options: string[]
  label?: string | null
  name: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  className?: string
  translateItems?: boolean
  isDisabled?: boolean
  style?: any
  flag?: boolean
  convert?(option: any): string

  setState(state: any): any

  setTouched?(touched: any): any
}

const SelectInput: React.FC<ISelectItems> = ({
  className,
  label,
  name,
  options,
  state,
  setState,
  translateItems,
  isDisabled,
  convert,
  errorText,
  style,
  flag,
}) => {
  const translate = useTranslate()

  const optionComponents = React.useMemo(
    () =>
      options.map((option: string) => {
        return (
          <option key={option} value={option} className={flag ? 'first-hiden' : ''}>
            {translateItems ? translate(option) : convert ? convert(option) : option}
          </option>
        )
      }),
    [options], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleChange = (event: any) => {
    return setState({
      ...state,
      [name]: event.target.value,
    })
  }

  return (
    <Form.Group className={className}>
      {Boolean(label) && (
        <Form.Label>
          <FormattedMessage id={label as string} tagName="span" />
          <span>:</span>
        </Form.Label>
      )}
      <Form.Control as="select" name={name} value={state[name]} onChange={handleChange} disabled={!!isDisabled} style={style}>
        {optionComponents}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        <FormattedMessage id={errorText || 'field-validation-error'} />
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default React.memo(SelectInput)
