import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withTooltip } from '@t4b/core/lib'

export function buildStringFromArray(items: string[], count: number = 2): string {
  return items.toString()
}

export function buildRange(begin: number, end: number): string {
  if (end === 0) {
    return begin.toString()
  }
  return `${begin}-${end}`
}

export function buildBoolean(value: boolean): JSX.Element {
  if (value) {
    return <FormattedMessage id="enabled" />
  }
  return <FormattedMessage id="disabled" />
}

export function buildTitle(item: any): JSX.Element {
  const danger = item.changed
    ? withTooltip(
        <span className="text-warning mr-1">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>,
        <FormattedMessage id="feeding-lp.changed" />,
        Math.random(),
      )
    : null
  return (
    <div>
      {danger}
      <span>{item.Name}</span>
    </div>
  )
}
