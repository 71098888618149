import { Action } from '../actions/actions'
import { BinanceRuleEntity } from '../../entity/binance'

const INITIAL_STATE = {
  BinanceFuturesSwap: [],
  noChanges: true,
}

function BinanceReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.BinanceFutures:
      return {
        ...state,
        BinanceFuturesSwap: action.data.map((item: any) => new BinanceRuleEntity(item)),
        noChanges: true,
      }
    case Action.AddBinanceFuturesSwap:
      return {
        ...state,
        BinanceFuturesSwap: [...state.BinanceFuturesSwap, new BinanceRuleEntity(action.data.body)],
        noChanges: false,
      }
    case Action.DeleteBinanceFutures:
      return {
        ...state,
        BinanceFuturesSwap: [],
        noChanges: false,
      }
    case Action.ModifyBinanceFutures:
      return {
        ...state,
        BinanceFuturesSwap: state.BinanceFuturesSwap.map((item: any) => {
          if (item.Id === action.data.body.Id) {
            return new BinanceRuleEntity(action.data.body)
          }
          return item
        }),
        noChanges: false,
      }
    case Action.DeleteBinanceFuturesOne:
      return {
        ...state,
        BinanceFuturesSwap: state.BinanceFuturesSwap.filter((symbol: any) => symbol.Id !== action.data.body.Id),
        noChanges: false,
      }
    case Action.ChangePriorityBinanceFutures: {
      const { result } = action.data
      const newSymbolArray = Array.from(state.BinanceFuturesSwap)
      newSymbolArray.splice(result.source.index, 1)
      newSymbolArray.splice(result.destination.index, 0, state.BinanceFuturesSwap[result.source.index])
      return {
        ...state,
        BinanceFuturesSwap: newSymbolArray,
        noChanges: false
      }
    }
    default:
      return state
  }
}

export function addBinanceFuturesSwap(data: any) {
  return { type: Action.AddBinanceFuturesSwap, data }
}

export function deleteBinanceFutures() {
  return { type: Action.DeleteBinanceFutures }
}

export function ModifyBinanceFutures(data: any) {
  return { type: Action.ModifyBinanceFutures, data }
}

export function DeleteBinanceFuturesOne(data: any) {
  return { type: Action.DeleteBinanceFuturesOne, data }
}

export default BinanceReducer
