import { Dispatch } from 'redux'
import {
  AggregationPoolEntity,
  ExecutionLpAEntity,
  ExecutionLpBEntity,
  ExecutionLpBRule,
  ProcessingRuleEntity,
  ProcessingRuleItem,
  RoutingRuleEntity,
} from '../../../entity/configuration'
import { FeedAgregationEntity, FeedPlatformEntity } from '../../../entity/feeding'
import { throwSuccessMessage } from '../../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../../utils/fetch-utils'
import { optionsToStrings, optionsToStringsConditional } from '../../../utils/multiselect-utils'
import { Action } from '../actions'
import { onlyFetchGateways } from '../platforms-actions'

export function changeRoutingRule(rule: RoutingRuleEntity) {
  rule.changed = true
  return { type: Action.ChangeRoutingRule, data: rule }
}

export function addRoutingRule(rule: RoutingRuleEntity) {
  rule.changed = true
  return { type: Action.AddRoutingRule, data: rule }
}

export function addProcessingRule(rule: any) {
  rule.changed = true
  return { type: Action.AddProcessingRule, data: rule }
}

export function changeProcessingRule(rule: any) {
  rule.changed = true
  return { type: Action.ChangeProcessingRule, data: rule }
}

export function enableProcessingRule(rule: ProcessingRuleEntity) {
  rule.changed = true
  return { type: Action.EnableProcessingRule, data: rule }
}

export function disableProcessingRule(rule: ProcessingRuleEntity) {
  rule.changed = true
  return { type: Action.DisableProcessingRule, data: rule }
}

export function addAggregationPool(pool: AggregationPoolEntity) {
  pool.changed = true
  return { type: Action.AddAggregationPool, data: pool }
}

export function changeAggregationPool(pool: AggregationPoolEntity) {
  pool.changed = true
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function deleteAggregationPool(pool: AggregationPoolEntity) {
  return { type: Action.DeleteAggergationPool, data: pool }
}

export function changeExecutionLp(lp: any) {
  lp.changed = true
  return { type: Action.ChangeExecutionLp, data: lp }
}

export function enableExecutionLp(lp: ExecutionLpAEntity | ExecutionLpBEntity) {
  lp.changed = true
  return { type: Action.EnableExecutionLp, data: lp }
}

export function disableExecutionLp(lp: ExecutionLpAEntity | ExecutionLpBEntity) {
  lp.changed = true
  return { type: Action.DisableExecutionLp, data: lp }
}

export function addFeedAggregation(aggregation: FeedAgregationEntity) {
  aggregation.changed = true
  return { type: Action.AddFeedAggregation, data: aggregation }
}

export function changeFeedAggregation(aggregation: FeedAgregationEntity) {
  aggregation.changed = true
  return { type: Action.ChangeFeedAggregation, data: aggregation }
}

export function deleteFeedAggregation(aggregation: FeedAgregationEntity) {
  return { type: Action.DeleteFeedAggregation, data: aggregation }
}

export function addFeedPlatform(platform: FeedPlatformEntity) {
  platform.changed = true
  return { type: Action.AddFeedPlatform, data: platform }
}

export function changeFeedPlatform(platform: FeedPlatformEntity) {
  platform.changed = true
  return { type: Action.ChangeFeedPlatform, data: platform }
}

export function deleteFeedPlatform(platform: FeedPlatformEntity) {
  return { type: Action.DeleteFeedPlatform, data: platform }
}

export function executionConfigurationInitial() {
  return { type: Action.ExecutionConfigurationInitial }
}

export function executionConfigurationChanged() {
  return { type: Action.ExecutionConfigurationChanged }
}

export function highlightRelevant(item: any) {
  return { type: Action.ExecutionHighlightRelevant, data: item }
}

export const executionSelector = (state: any) => state.executionConfiguration

export function fetchExecutionUpdate(arg: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/execution/update', window.location.origin)
    url.searchParams.set('gateway', arg.params.Gateway)

    const { params, body } = arg
    const changableObj = { changed: undefined, highlighted: undefined, activeSwitches: undefined }
    const req: any = {}

    req.RoutingRules = body.routingRules.map((item: RoutingRuleEntity) => ({
      ...item,
      OrderTypes: optionsToStrings(item.OrderTypes),
      Platforms: optionsToStrings(item.Platforms),
      Schedule: {
        ...item.Schedule,
        Days: optionsToStrings(item.Schedule.Days),
      },
      ...changableObj,
    }))

    req.ProcessingRules = body.processingRules.map((item: ProcessingRuleEntity) => ({
      ...item,
      Rules: item.Rules.map((rule: ProcessingRuleItem) => ({
        ...rule,
        OrderTypes: optionsToStrings(rule.OrderTypes),
      })),
      ...changableObj,
    }))

    req.AggregationPools = body.aggregationPools.map((item: AggregationPoolEntity) => ({
      ...item,
      Lps: optionsToStrings(item.Lps),
      ...changableObj,
    }))

    req.LpsA = body.lps.filter((item: any) => !item.Type.match(/^Bbook/)).map((item: any) => ({ ...item, ...changableObj }))

    req.LpsB = body.lps
      .filter((item: any) => item.Type.match(/^Bbook/))
      .map((item: any) => ({
        ...item,
        Rules: item.Rules.map((rule: ExecutionLpBRule) => ({
          ...rule,
          OrderTypes: optionsToStringsConditional(rule.OrderTypes),
        })),
        ...changableObj,
      }))

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`execution.status.${result.Status}`)))
        }
        return onlyFetchExecution(arg.params)
      })
      .then((data: any) => {
        dispatch({ type: Action.GotRoutingRules, data: data.RoutingRules })
        dispatch({
          type: Action.GotProcessingRules,
          data: data.ProcessingRules,
        })
        dispatch({
          type: Action.GotAggregationPools,
          data: data.AggregationPools,
        })
        dispatch({
          type: Action.GotExecutionLP,
          data: [...data.LpsA, ...data.LpsB],
        })
        dispatch(executionConfigurationInitial())
        throwSuccessMessage('Successfully')
        return onlyFetchGateways()
      })
      .then((response: Response) => checkResponse(response))
      .then(gates => dispatch({ type: Action.GotGateways, data: gates }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchExecution(params: any) {
  const url = new URL('/api/execution', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchExecution(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchExecution(params)
      .then((data: any) => {
        dispatch({ type: Action.GotRoutingRules, data: data.RoutingRules })
        dispatch({
          type: Action.GotProcessingRules,
          data: data.ProcessingRules,
        })
        dispatch({
          type: Action.GotAggregationPools,
          data: data.AggregationPools,
        })
        dispatch({
          type: Action.GotExecutionLP,
          data: [...data.LpsA, ...data.LpsB],
        })
        dispatch(executionConfigurationInitial())
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function getProcessingRuleItems(data: any) {
  return { type: Action.GotProcessingRuleItems, data }
}

export function addProcessingRuleItem(data: any) {
  return { type: Action.AddProcessingRuleItem, data }
}

export function addProcessingRuleItems(data: any) {
  return { type: Action.AddProcessingRuleItems, data }
}

export function changeProcessingRuleItem(data: any) {
  return { type: Action.ChangeProcessingRuleItem, data }
}

export function deleteProcessingRuleItem(data: any) {
  return { type: Action.DeleteProcessingRuleItem, data }
}

export function deleteProcessingRuleItems(data: any) {
  return { type: Action.DeleteProcessingRuleItems, data }
}

export function changeProcessingRuleItemPriority(data: any) {
  return { type: Action.ChangeProcessingRuleItemPriority, data }
}

export function getExecutionLpRules(data: any) {
  return { type: Action.GotExecutionLpBRules, data }
}

export function addExecutionLpRule(data: any) {
  return { type: Action.AddExecutionLpBRule, data }
}

export function changeExecutionLpRule(data: { rule: ExecutionLpBRule; params: { Lp: string } }) {
  return { type: Action.ChangeExecutionLpBRule, data }
}

export function deleteExecutionLpRule(data: any) {
  return { type: Action.DeleteExecutionLpBRule, data }
}

export function deleteExecutionLpRules(data: any) {
  return { type: Action.DeleteExecutionLpBRules, data }
}

export function changeExecutionLpRulePriority(data: any) {
  return { type: Action.ChangeExecutionLpBRulePriority, data }
}
