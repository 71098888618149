import React from 'react'
import { FormattedMessage } from 'react-intl'

const NotEnoughPrivelege: React.FC = () => {
  return (
    <h2>
      <FormattedMessage id="not-enough-privelege" />
    </h2>
  )
}
export default NotEnoughPrivelege
