import * as yup from 'yup'
import { buildMultiselectOptionsFromArray, buildSelectOption } from '../utils/multiselect-utils'
import { expToNum } from '../utils/num-utils'
import { passwordSchema, portSchema, transformEmptyString } from '../utils/schema-utils'
import { END_OF_DAY, START_OF_DAY } from '../utils/time-utils'
import { ConnectionString, Day, RmqSettings } from './configuration'
import { IMultiSelectItem, MultiselectItem } from './multiselect'

export enum PlatformType {
  mt4 = 'mt4',
  mt5 = 'mt5',
  fixapi = 'fixapi',
  extapi = 'extapi',
  mtexec = 'mtexec',
}

export class PlatformSymbol {
  PlatformSymbol: IMultiSelectItem
  GatewaySymbol: IMultiSelectItem
  Digits: number
  VolumeStep: string
  ContractSize: number

  constructor(item?: any) {
    if (item) {
      this.PlatformSymbol = buildSelectOption(item.PlatformSymbol)
      this.GatewaySymbol = buildSelectOption(item.GatewaySymbol)
      this.Digits = item.Digits
      this.VolumeStep = expToNum(item.VolumeStep)
      this.ContractSize = item.ContractSize
    } else {
      this.PlatformSymbol = new MultiselectItem()
      this.GatewaySymbol = new MultiselectItem()
      this.Digits = 0
      this.VolumeStep = '0'
      this.ContractSize = 0
    }
  }
}

export function getPlatformType(platforms: any[], name: string) {
  for (const platform of platforms) {
    if (platform.Name === name) {
      return platform.Type
    }
  }
  return ''
}

export class Session {
  SenderCompId: string
  TargetCompId: string
  BeginString: string
  Username: string
  Password: string
  DefaultAccount: string
  Accounts: IMultiSelectItem[]
  Groups: IMultiSelectItem[];

  [key: string]: any

  T4bFixProtocolVersion: any

  static schema = {
    SenderCompId: yup.string().max(32).required(),
    TargetCompId: yup.string().max(32).required(),
    BeginString: yup.string().required(),
    Username: yup.string().required(),
    Password: yup.string().required(),
    DefaultAccount: yup.string().required(),
    Accounts: yup.array().required(),
    Groups: yup.array().required(),
  }

  static schemaFixApi = {
    SenderCompId: yup.string().max(32).required(),
    TargetCompId: yup.string().max(32).required(),
    BeginString: yup.string().required(),
    Username: yup.string().required(),
    Password: yup.string().required(),
    DefaultAccount: yup.string().required(),
    Accounts: yup.array().required(),
    Groups: yup.array().required(),
  }

  static schemaExtApi = {
    SenderCompId: yup.string().max(32).required(),
    TargetCompId: yup.string().max(32).required(),
    BeginString: yup.string().required(),
    Username: yup.string().required(),
    Password: yup.string().required(),
  }

  static schemaMt4 = {
    SenderCompId: yup.string().max(32).required(),
    TargetCompId: yup.string().max(32).required(),
    BeginString: yup.string().required(),
    Username: yup.string().required(),
    Password: yup.string().required(),
    // DefaultAccount: yup.string().notRequired(),
    // Accounts: yup.array().notRequired(),
    // Groups: yup.array().notRequired()
  }

  constructor(item?: any) {
    if (item) {
      this.SenderCompId = item.SenderCompId
      this.TargetCompId = item.TargetCompId
      this.BeginString = item.BeginString
      this.Username = item.Username
      this.Password = item.Password
      this.DefaultAccount = item.DefaultAccount
      this.Accounts = item.Accounts ? buildMultiselectOptionsFromArray(item.Accounts) : []
      this.Groups = item.Groups ? buildMultiselectOptionsFromArray(item.Groups) : []
      this.T4bFixProtocolVersion = item.T4bFixProtocolVersion ? item.T4bFixProtocolVersion : 'FIXAPI 3.2'
    } else {
      this.T4bFixProtocolVersion = 'FIXAPI 3.2'
      this.SenderCompId = ''
      this.TargetCompId = ''
      this.BeginString = 'FIX.4.4'
      this.Username = ''
      this.Password = ''
      this.DefaultAccount = ''
      this.Accounts = buildMultiselectOptionsFromArray(['*'])
      this.Groups = buildMultiselectOptionsFromArray(['*'])
    }
  }
}

export class Market {
  Symbol: string
  TifFrom: string
  TifTo: string

  constructor(item?: any) {
    if (item) {
      this.Symbol = item.Symbol
      this.TifFrom = item.TifFrom
      this.TifTo = item.TifTo
    } else {
      this.Symbol = ''
      this.TifFrom = ''
      this.TifTo = ''
    }
  }
}

export class Limit {
  Symbol: string
  Mode: string

  constructor(item?: any) {
    if (item) {
      this.Symbol = item.Symbol
      this.Mode = item.Mode
    } else {
      this.Symbol = ''
      this.Mode = ''
    }
  }
}

export class LpSession {
  SenderCompId: string
  TargetCompId: string
  BeginString: string
  Username: string
  Password: string
  Account: string
  ReconnectInterval: number
  FileStorePath: string
  FileLogPath: string
  WriteLogs: boolean
  StartTime: number
  EndTime: number
  SocketConnectHost: string
  SocketConnectPort: number
  Server: string
  Login: string
  QueryInterval: number
  Brand: string
  PartyId: string
  OnBehalfOfCompId: string
  IgnoreLogOptions: string
  MaxMessagesPerSec: number
  SendRedundantResendRequests: boolean
  ResetOnLogon: boolean
  ResetOnLogout: boolean
  ResetOnDisconnect: boolean
  TargetSubID: string
  SenderSubId: string
  ResubscribeOnRejectTimeout: number
  QuoteTextProcessing: boolean
  SslEnable: boolean
  SslCertificate: string
  SslCertificatePassword: string | null
  SslCaCertificate: string
  SslServerName: string
  TifOverrides: any;

  [key: string]: any

  constructor(item?: any) {
    if (item) {
      this.SenderCompId = item.SenderCompId
      this.TargetCompId = item.TargetCompId
      this.BeginString = item.BeginString
      this.Username = item.Username
      this.Password = item.Password
      this.Account = item.Account
      this.ReconnectInterval = item.ReconnectInterval
      this.FileStorePath = item.FileStorePath
      this.FileLogPath = item.FileLogPath
      this.WriteLogs = item.WriteLogs
      this.StartTime = item.StartTime ?? START_OF_DAY
      this.EndTime = item.EndTime ?? END_OF_DAY
      this.SocketConnectHost = item.SocketConnectHost
      this.SocketConnectPort = item.SocketConnectPort
      this.Server = item.Server
      this.Login = item.Login
      this.QueryInterval = item.QueryInterval
      this.Brand = item.Brand
      this.PartyId = item.PartyId
      this.OnBehalfOfCompId = item.OnBehalfOfCompId
      this.IgnoreLogOptions = item.IgnoreLogOptions
      this.MaxMessagesPerSec = item.MaxMessagesPerSec
      this.SendRedundantResendRequests = item.SendRedundantResendRequests
      this.Brand = item.Brand
      this.PartyId = item.PartyId
      this.ResetOnLogon = item.ResetOnLogon
      this.ResetOnLogout = item.ResetOnLogout
      this.ResetOnDisconnect = item.ResetOnDisconnect
      this.TargetSubID = item.TargetSubID
      this.SenderSubId = item.SenderSubId
      this.ResubscribeOnRejectTimeout = item.ResubscribeOnRejectTimeout
      this.QuoteTextProcessing = item.QuoteTextProcessing
      this.SslEnable = item.SslEnable
      this.SslCertificate = item.SslCertificate
      this.SslCertificatePassword = item.SslCertificatePassword
      this.SslCaCertificate = item.SslCaCertificate
      this.SslServerName = item.SslServerName
      this.TifOverrides = {
        Market: item?.TifOverrides?.Market,
        Limit: item?.TifOverrides?.Limit,
      }
    } else {
      this.TifOverrides = {
        Market: [new Market()],
        Limit: [new Limit()],
      }
      this.SenderCompId = ''
      this.TargetCompId = ''
      this.BeginString = 'FIX.4.4'
      this.Username = ''
      this.Password = ''
      this.Account = ''
      this.ReconnectInterval = 10
      this.FileStorePath = ''
      this.FileLogPath = ''
      this.WriteLogs = true
      this.StartTime = START_OF_DAY
      this.EndTime = END_OF_DAY
      this.SocketConnectHost = 'localhost'
      this.SocketConnectPort = 8080
      this.Server = ''
      this.Login = ''
      this.QueryInterval = 0
      this.Brand = ''
      this.PartyId = ''
      this.OnBehalfOfCompId = ''
      this.IgnoreLogOptions = ''
      this.MaxMessagesPerSec = 1000
      this.SendRedundantResendRequests = false
      this.ResetOnLogon = false
      this.ResetOnLogout = false
      this.ResetOnDisconnect = false
      this.TargetSubID = ''
      this.SenderSubId = ''
      this.ResubscribeOnRejectTimeout = 30
      this.QuoteTextProcessing = false
      this.SslEnable = false
      this.SslCertificate = ''
      this.SslCertificatePassword = null
      this.SslCaCertificate = ''
      this.SslServerName = ''
    }
  }
}

export class AccountEntity {
  Login: string
  Currency: string
  Group: string
  Leverage: number
  StopOut: number
  MarginOnActivate: boolean

  constructor(item?: any) {
    if (item) {
      this.Login = item.Login
      this.Currency = item.Currency
      this.Group = item.Group
      this.Leverage = item.Leverage
      this.StopOut = item.StopOut
      this.MarginOnActivate = item.MarginOnActivate
    } else {
      this.Login = ''
      this.Currency = ''
      this.Group = ''
      this.Leverage = 1
      this.StopOut = 0
      this.MarginOnActivate = false
    }
  }
}

export class MarginInfoEntity {
  Login: number
  Balance: number
  FloatingProfit: number
  Margin: number
  ReservedMargin: number
  Equity: number
  FreeMargin: number
  MarginLevel: number

  constructor(item: any) {
    this.Login = item.Login
    this.Balance = item.Balance
    this.FloatingProfit = item.FloatingProfit
    this.Margin = item.Margin
    this.ReservedMargin = item.ReservedMargin
    this.Equity = item.Equity
    this.FreeMargin = item.FreeMargin
    this.MarginLevel = item.MarginLevel
  }
}

export class Swap {
  SenderCompId: string
  TargetCompId: string
  Days: IMultiSelectItem[]
  Time: number
  Multiplier: number

  constructor(item?: any) {
    if (item) {
      this.SenderCompId = item.SenderCompId
      this.TargetCompId = item.TargetCompId
      this.Days = buildMultiselectOptionsFromArray(item.Days)
      this.Time = item.Time
      this.Multiplier = item.Multiplier
    } else {
      this.SenderCompId = '*'
      this.TargetCompId = '*'
      this.Days = [{ value: Day.Everyday, label: Day.Everyday }]
      this.Time = 0
      this.Multiplier = 1
    }
  }
}

export class Pair {
  public Name: string
  public Value: number

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.Value = item.Value
    } else {
      this.Name = '*'
      this.Value = 0
    }
  }
}

export function convertPlatformFields(objectToConvert: any) {
  const newObject = { ...objectToConvert }

  if (newObject.GatewayAddress) {
    newObject.GatewayAddress = newObject.GatewayPort ? newObject.GatewayAddress + ':' + newObject.GatewayPort : newObject.GatewayAddress
    newObject.GatewayPort = undefined
  }

  if (newObject.ServerAddress) {
    newObject.ServerAddress = newObject.ServerPort ? newObject.ServerAddress + ':' + newObject.ServerPort : newObject.ServerAddress
    newObject.ServerPort = undefined
  }

  if (newObject.Mt4Server) {
    newObject.Mt4Server = newObject.Mt4ServerPort ? newObject.Mt4Server + ':' + newObject.Mt4ServerPort : newObject.Mt4Server
    newObject.Mt4ServerPort = undefined
  }

  if (newObject.Mt5Server) {
    newObject.Mt5Server = newObject.Mt5ServerPort ? newObject.Mt5Server + ':' + newObject.Mt5ServerPort : newObject.Mt5Server
    newObject.Mt5ServerPort = undefined
  }

  return newObject
}

export class FixSymbolEntity {
  public PlatformSymbol: string
  public ContractMultiplier: number
  public ContractSize: number
  public Type: string
  public BaseCurrency: string
  public QuoteCurrency: string
  public CanBeUsedForConvert: boolean
  public Digits: number
  public VolumeStep: number
  public StrongHedgedMargin: boolean
  public InvalidateTimeout: number
  public Commission: number
  public SwapLong: number
  public SwapShort: number
  public DisableFOK: boolean

  constructor(item?: any) {
    if (item) {
      this.PlatformSymbol = item.PlatformSymbol
      this.ContractMultiplier = item.ContractMultiplier
      this.ContractSize = item.ContractSize
      this.Type = item.Type
      this.BaseCurrency = item.BaseCurrency
      this.QuoteCurrency = item.QuoteCurrency
      this.CanBeUsedForConvert = item.CanBeUsedForConvert
      this.Digits = item.Digits
      this.VolumeStep = item.VolumeStep
      this.StrongHedgedMargin = item.StrongHedgedMargin
      this.InvalidateTimeout = item.InvalidateTimeout
      this.Commission = item.Commission
      this.SwapLong = item.SwapLong
      this.SwapShort = item.SwapShort
      this.DisableFOK = item.DisableFOK
    } else {
      this.PlatformSymbol = ''
      this.ContractMultiplier = 1.0
      this.ContractSize = 1
      this.Type = 'Forex'
      this.BaseCurrency = ''
      this.QuoteCurrency = ''
      this.CanBeUsedForConvert = true
      this.Digits = 5
      this.VolumeStep = 1
      this.StrongHedgedMargin = false
      this.InvalidateTimeout = 30
      this.Commission = 0
      this.SwapLong = 0
      this.SwapShort = 0
      this.DisableFOK = false
    }
  }
}

export class ExtSymbolEntity {
  public PlatformSymbol: string
  public ContractMultiplier: number
  public ContractSize: number
  public Type: string
  public BaseCurrency: string
  public QuoteCurrency: string
  public CanBeUsedForConvert: boolean
  public Digits: number
  public VolumeStep: number

  constructor(item?: any) {
    if (item) {
      this.PlatformSymbol = item.PlatformSymbol
      this.ContractMultiplier = item.ContractMultiplier
      this.ContractSize = item.ContractSize
      this.Type = item.Type
      this.BaseCurrency = item.BaseCurrency
      this.QuoteCurrency = item.QuoteCurrency
      this.CanBeUsedForConvert = item.CanBeUsedForConvert
      this.Digits = item.Digits
      this.VolumeStep = item.VolumeStep
    } else {
      this.PlatformSymbol = ''
      this.ContractMultiplier = 1.0
      this.ContractSize = 1
      this.Type = 'Forex'
      this.BaseCurrency = ''
      this.QuoteCurrency = ''
      this.CanBeUsedForConvert = true
      this.Digits = 5
      this.VolumeStep = 1
    }
  }
}

export class Mt5PlatformEntity {
  Type: PlatformType
  Name: string
  GatewayAddress: string
  GatewayPort: string
  GatewayLogin: number
  GatewayPassword: string
  ManagerLogin: number
  ManagerPassword: string
  ServerAddress: string
  ServerPort: string
  ServerOwner: string
  AutoSwitchApiAddress: string
  Enabled: boolean
  ConnectionString: ConnectionString

  static schema(type: string) {
    const s = () => yup.string().required()

    return {
      Type: s(),
      Name: s(),
      GatewayAddress: s(),
      GatewayPort: portSchema(),
      GatewayLogin: s(),
      GatewayPassword: passwordSchema(type),
      ManagerLogin: yup.number().required(),
      ManagerPassword: passwordSchema(type),
      ServerAddress: s(),
      ServerPort: portSchema(),
      ServerName: yup.string().notRequired(),
      ServerOwner: yup.string().notRequired(),
      AutoSwitchApiAddress: s(),
    }
  }

  constructor(item?: any) {
    if (item) {
      this.Type = item.Type || PlatformType.mt5
      this.Name = item.Name
      this.GatewayAddress = (item.GatewayAddress && item.GatewayAddress.split(':')[0]) || 'localhost'
      this.GatewayPort = (item.GatewayAddress && item.GatewayAddress.split(':')[1]) || '12300'
      this.GatewayLogin = item.GatewayLogin || 100
      this.GatewayPassword = item.GatewayPassword || ''
      this.ManagerLogin = item.ManagerLogin || 1000
      this.ManagerPassword = item.ManagerPassword || ''
      this.ServerAddress = (item.ServerAddress && item.ServerAddress.split(':')[0]) || 'localhost'
      this.ServerPort = (item.ServerAddress && item.ServerAddress.split(':')[1]) || '1950'
      this.ServerOwner = item.ServerOwner || ''
      this.AutoSwitchApiAddress = item.AutoSwitchApiAddress || ''
      this.Enabled = item.Enabled
      this.ConnectionString = new ConnectionString(item.ConnectionString || {})
    } else {
      this.Type = PlatformType.mt5
      this.Name = ''
      this.GatewayAddress = 'localhost'
      this.GatewayPort = '12300'
      this.GatewayLogin = 100
      this.GatewayPassword = ''
      this.ManagerLogin = 1000
      this.ManagerPassword = ''
      this.ServerAddress = 'localhost'
      this.ServerPort = '1950'
      this.ServerOwner = ''
      this.AutoSwitchApiAddress = ''
      this.Enabled = true
      this.ConnectionString = new ConnectionString({
        Server: 'localhost',
        Port: 5432,
        UserId: '',
        Password: '',
        Database: '',
      })
    }
  }
}

export class Mt4PlatformEntity {
  Type: PlatformType
  Name: string
  Enabled: boolean
  SocketAcceptPort: number

  Mt4ServerName: string
  OrderRestoreInterval: number
  ConfirmRequestsEnable: boolean

  SSLEnable: boolean
  StartTime: number
  EndTime: number
  UseLocalTime: boolean
  Mt4Server: string
  Mt4Login: string
  Mt4Password: string
  Mt4ServerPort: string
  FileStorePath: string
  FileLogPath: string
  RMQ: RmqSettings
  ConnectionString: ConnectionString

  static schema(type: string) {
    const s = () => yup.string().required()
    const n = () => yup.number().required()

    return {
      Type: s(),
      Name: s(),
      Enabled: yup.boolean().required(),
      SocketAcceptPort: portSchema(),

      Mt4ServerName: s(),
      OrderRestoreInterval: n(),
      ConfirmRequestsEnable: yup.boolean().required(),

      SSLEnable: yup.boolean().required(),
      UseLocalTime: yup.boolean().required(),
      StartTime: yup.number().required(),
      EndTime: yup.number().required(),

      Mt4Server: s(),
      Mt4Login: s(),
      Mt4Password: passwordSchema(type),
      Mt4ServerPort: portSchema(),
      FileStorePath: s(),
      FileLogPath: s(),
    }
  }

  constructor(item?: Partial<Mt4PlatformEntity>) {
    if (item === undefined) {
      item = {}
    }
    this.Type = item.Type ?? PlatformType.mt4
    this.Name = item.Name ?? ''
    this.Mt4ServerName = item.Mt4ServerName ?? ''
    this.OrderRestoreInterval = item.OrderRestoreInterval ?? 1800
    this.ConfirmRequestsEnable = item.ConfirmRequestsEnable ?? false
    this.SSLEnable = item.SSLEnable ?? false
    this.StartTime = item.StartTime ?? START_OF_DAY
    this.EndTime = item.EndTime ?? END_OF_DAY
    this.UseLocalTime = item.UseLocalTime ?? false
    this.Mt4Login = item.Mt4Login ?? ''
    this.Mt4Password = item.Mt4Password ?? ''
    this.Mt4Server = item.Mt4Server?.split(':')[0] ?? ''
    this.Mt4ServerPort = item.Mt4Server?.split(':')[1] ?? ''
    this.SocketAcceptPort = item.SocketAcceptPort ?? 12333
    this.FileStorePath = item.FileStorePath ?? ''
    this.FileLogPath = item.FileLogPath ?? ''
    this.Enabled = item.Enabled ?? true
    this.RMQ = new RmqSettings(item.RMQ ?? {})
    this.ConnectionString = new ConnectionString(item.ConnectionString ?? ConnectionString.getDefault())
  }
}

export class MtExecPlatformEntity {
  Type: PlatformType
  Name: string
  SocketAcceptPort: number
  UseLocalTime: boolean
  StartTime: number
  EndTime: number
  SSLEnable: boolean
  Mt5Server: string
  Mt5ServerPort: string
  Mt5Login: number
  Mt5Password: string | null
  FileStorePath: string
  FileLogPath: string
  Enabled: boolean
  ConnectionString: ConnectionString

  static schema(type: string) {
    const s = () => yup.string().required()
    const snr = () => yup.string().notRequired()

    return {
      Type: s(),
      Name: s(),
      SocketAcceptPort: portSchema(),
      UseLocalTime: yup.boolean().required(),
      StartTime: yup.number().required(),
      EndTime: yup.number().required(),
      SSLEnable: yup.boolean().required(),

      Mt5Server: snr(),
      Mt5ServerPort: portSchema().notRequired(),
      Mt5Login: yup.number().transform(transformEmptyString).nullable().notRequired(),
      Mt5Password: passwordSchema(type),
      FileStorePath: s(),
      FileLogPath: s(),
      Enabled: yup.boolean().required(),
    }
  }

  constructor(item?: any) {
    if (item === undefined) {
      item = {}
    }
    this.Type = item.Type ?? PlatformType.mtexec
    this.Name = item.Name ?? ''
    this.SocketAcceptPort = item.SocketAcceptPort ?? 12333
    this.UseLocalTime = item.UseLocalTime ?? false
    this.StartTime = item.StartTime ?? START_OF_DAY
    this.EndTime = item.EndTime ?? END_OF_DAY
    this.SSLEnable = item.SSLEnable ?? false
    this.Mt5Server = item.Mt5Server?.split(':')[0] || 'localhost'
    this.Mt5ServerPort = item.Mt5Server?.split(':')[1] || '443'
    this.Mt5Login = item.Mt5Login ?? 1000
    this.Mt5Password = item.Mt5Password ?? null
    this.FileStorePath = item.FileStorePath ?? ''
    this.FileLogPath = item.FileLogPath ?? ''
    this.Enabled = item.Enabled ?? true
    this.ConnectionString = new ConnectionString(item.ConnectionString || ConnectionString.getDefault())
  }
}

export class FixApiPlatformEntity {
  Type: PlatformType
  Name: string
  SocketAcceptPort: number
  UseLocalTime: boolean
  StartTime: number
  EndTime: number
  SSLEnable: boolean
  FileStorePath: string
  FileLogPath: string
  Enabled: boolean
  ConnectionString: ConnectionString
  SslCaCertificate: string
  SslCertificate: string
  SslCertificatePassword: string | null
  SslServerName: string
  SslCheckCertificateRevocation: boolean
  SslRequireClientCertificate: boolean
  SslValidateCertificates: boolean

  static schema() {
    const s = () => yup.string().required()

    return {
      Type: s(),
      Name: s(),
      SocketAcceptPort: portSchema(),
      UseLocalTime: yup.boolean().required(),
      StartTime: yup.number().required(),
      EndTime: yup.number().required(),
      SSLEnable: yup.boolean().required(),
      FileStorePath: s(),
      FileLogPath: s(),
      Enabled: yup.boolean().required(),
      SslCaCertificate: yup.string(),
      SslCertificate: yup.string(),
      SslServerName: yup.string(),
    }
  }

  constructor(item?: any) {
    if (item === undefined) {
      item = {}
    }

    this.Type = item.Type ?? PlatformType.fixapi
    this.Name = item.Name ?? ''
    this.SocketAcceptPort = item.SocketAcceptPort ?? 12333
    this.UseLocalTime = item.UseLocalTime ?? false
    this.StartTime = item.StartTime ?? START_OF_DAY
    this.EndTime = item.EndTime ?? END_OF_DAY
    this.SSLEnable = item.SSLEnable ?? false
    this.FileStorePath = item.FileStorePath ?? ''

    this.SslCaCertificate = item.SslCaCertificate ?? ''
    this.SslCertificate = item.SslCertificate ?? ''
    this.SslCertificatePassword = null
    this.SslServerName = item.SslServerName ?? ''
    this.SslCheckCertificateRevocation = item.SslCheckCertificateRevocation ?? false
    this.SslRequireClientCertificate = item.SslRequireClientCertificate ?? false
    this.SslValidateCertificates = item.SslValidateCertificates ?? false

    this.FileLogPath = item.FileLogPath ?? ''
    this.Enabled = item.Enabled ?? true
    this.ConnectionString = new ConnectionString(item.ConnectionString ?? ConnectionString.getDefault())
  }
}

export class ExtApiPlatformEntity {
  Type: PlatformType
  Name: string
  SocketAcceptPort: number
  UseLocalTime: boolean
  StartTime: number
  EndTime: number
  SSLEnable: boolean
  FileStorePath: string
  FileLogPath: string
  Enabled: boolean
  ConnectionString: ConnectionString

  static schema() {
    const s = () => yup.string().required()

    return {
      Type: s(),
      Name: s(),
      SocketAcceptPort: portSchema(),
      UseLocalTime: yup.boolean().required(),
      StartTime: yup.number().required(),
      EndTime: yup.number().required(),
      SSLEnable: yup.boolean().required(),
      FileStorePath: s(),
      FileLogPath: s(),
      Enabled: yup.boolean().required(),
    }
  }

  constructor(item?: any) {
    if (item === undefined) {
      item = {}
    }

    this.Type = item.Type ?? PlatformType.extapi
    this.Name = item.Name ?? ''
    this.SocketAcceptPort = item.SocketAcceptPort ?? 12333
    this.UseLocalTime = item.UseLocalTime ?? false
    this.StartTime = item.StartTime ?? START_OF_DAY
    this.EndTime = item.EndTime ?? END_OF_DAY
    this.SSLEnable = item.SSLEnable ?? false
    this.FileStorePath = item.FileStorePath ?? ''
    this.FileLogPath = item.FileLogPath ?? ''
    this.Enabled = item.Enabled ?? true
    this.ConnectionString = new ConnectionString(item.ConnectionString || ConnectionString.getDefault())
  }
}
