import { Dispatch } from 'redux'
import { Action } from '../redux/actions/actions'
import { getJwt } from '../redux/actions/authentication'
import { showToastError, throwErrorMessage, throwSuccessMessage } from './errors-utils'
import { ISortOptions } from './table-types'
import { IPagination } from '../hooks/usePagination'
import { GatewayEntity } from '../entity/system-settings'

export function onlyFetchBuilder(uri: string, scheme?: IIndexable<boolean>): (params: IIndexable) => Promise<any> {
  return (params: IIndexable) => {
    const url = new URL(uri, document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    if (scheme) {
      for (const queryParam in scheme) {
        if (queryParam === 'Symbol') {
          url.searchParams.set(queryParam, params[queryParam].value)
        } else if (queryParam === 'Search') {
          if (params[queryParam]) {
            url.searchParams.set(queryParam, params[queryParam])
          }
        } else {
          url.searchParams.set(queryParam, params[queryParam])
        }
      }
    }

    if (params.field && params.field.length && params.by !== 'none') {
      url.searchParams.set('sort', params.field)
      url.searchParams.set('by', params.by)
    }

    return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
  }
}

type OnlyFetchFunc = ReturnType<typeof onlyFetchBuilder>

export function fetchBuilder(fetchCallback: OnlyFetchFunc, action: Action | string) {
  return (params: any) => {
    return (dispatch: Dispatch) => {
      dispatch({ type: Action.InProgressStart })
      if (typeof params.setLoading === 'function') {
        params.setLoading(true)
      }
      return fetchCallback(params)
        .then((data: any) => dispatch({ type: action, data }))
        .catch((error: Error) => processError(error, dispatch))
        .finally(() => {
          dispatch({ type: Action.InProgressEnd })
          if (typeof params.setLoading === 'function') {
            params.setLoading(false)
          }
        })
    }
  }
}

export function modifyBuilder(uri: string, fetchCallback: any, reduxAction: any, scheme?: any, errorHandler?: any) {
  return (arg: any) => {
    const { action, body, params } = arg
    return (dispatch: Dispatch) => {
      let url = new URL(`${uri}/${action === 'clone' ? 'add' : action}`, document.location.origin)
      if (action === '') {
        url = new URL(uri, document.location.origin)
      }
      url.searchParams.set('gateway', params.Gateway)

      if (scheme) {
        for (const queryParam in scheme) {
          url.searchParams.set(queryParam, params[queryParam])
        }
      }
      dispatch({ type: Action.InProgressStart })
      if (typeof params.setLoading === 'function') {
        params.setLoading(true)
      }

      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
        body: body ? JSON.stringify(body) : undefined,
      })
        .then((response: Response) => checkResponse(response))
        .then((result: any) => {
          if (result.Status) {
            if (errorHandler) {
              return errorHandler(result.Status)
            } else {
              console.log(result)
              return new Promise((resolve, reject) =>
                reject(result.Description ? showToastError('api.error.delete-errors', result) : new Error(`${result.Status}`)),
              )
            }
          } else {
            throwSuccessMessage('Successfully')
            return fetchCallback(params)
          }
        })
        .then((obj: any) => dispatch({ type: reduxAction, data: obj }))
        .catch((error: Error) => processError(error, dispatch))
        .finally(() => {
          dispatch({ type: Action.InProgressEnd })
          if (typeof params.setLoading === 'function') {
            params.setLoading(false)
          }
        })
    }
  }
}

export function checkResponse(response: Response, dispatch?: any) {
  if (response.ok) {
    return response.json()
  }

  return new Promise((resolve, reject) => reject(new Error(response.status.toString())))
}

export function checkTextResponse(response: Response) {
  if (response.ok) {
    return response.blob()
  }
  return new Promise((resolve, reject) => reject(new Error(response.status.toString())))
}

export function processError(error: any, dispatch: Dispatch): void {
  console.log(error)
  if (error?.message === 'unauthorized') {
    dispatch({ type: Action.Unauthorized })
  }
  if (error?.message) {
    throwErrorMessage(error.message)
  }
}

export function buildHTTPPostOptions(bodyObject: any): RequestInit {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${getJwt()}`,
    },
    body: bodyObject ? JSON.stringify(bodyObject) : undefined,
  }
}

export function buildHTTPGetOptions(): RequestInit {
  return {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }
}

export function downloadFile(fileName: string, data: any): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: 'text/plain' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFileJson(fileName: string, data: any): void {
  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([data], { type: 'application/json' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadFileCVS(fileName: string, data: any): void {
  const keys = Object.keys(data[0]).slice(0, 3)

  let result = keys.join('\t') + '\n'

  data.forEach(function (obj: any) {
    result += keys.map(k => obj[k]).join('\t') + '\n'
  })

  const link = document.createElement('a')
  link.download = fileName
  const blob = new Blob([result], { type: 'text/csv' })
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}

export interface IIndexable<T = any> {
  [key: string]: T
}

export function buildURL(path: string, ...queryParams: IIndexable[]): string {
  const url = new URL(path, document.location.origin)

  for (const queryParamsObject of queryParams) {
    for (const param of Object.keys(queryParamsObject)) {
      if (Array.isArray(queryParamsObject[param])) {
        for (const paramItem of queryParamsObject[param]) {
          url.searchParams.append(param, paramItem)
        }
      } else {
        url.searchParams.set(param, queryParamsObject[param])
      }
    }
  }

  return url.toString()
}

export async function fetchGet(path: string, ...queryParams: IIndexable[]): Promise<any> {
  try {
    const response = await fetch(buildURL(path, ...queryParams), buildHTTPGetOptions())
    return await checkResponse(response)
  } catch (e) {
    throw e
  }
}

export async function fetchPost(path: string, body: any, ...queryParams: IIndexable[]): Promise<any> {
  try {
    const response = await fetch(buildURL(path, ...queryParams), buildHTTPPostOptions(body))
    return await checkResponse(response)
  } catch (e) {
    throw e
  }
}

export function transformSortOptions(params: ISortOptions): IIndexable {
  if (params.field.length && params.by !== 'none') {
    return {
      sort: params.field,
      by: params.by,
    }
  }
  return {}
}

export function transformPagination(pagination: IPagination): IIndexable {
  return {
    page: pagination.Page,
    count: pagination.Count,
  }
}

export function transformGateway(gateway: GatewayEntity): IIndexable {
  return {
    gateway: gateway.Name,
  }
}

export function wsSchema(): string {
  return window.location.protocol === 'https:' ? 'wss' : 'ws'
}
