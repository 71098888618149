import moment from 'moment'
import { Dispatch } from 'redux'
import { throwSuccessMessage, throwWarnMessage } from '../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, fetchBuilder, modifyBuilder, onlyFetchBuilder, processError } from '../../utils/fetch-utils'
import { Action } from './actions'
import axios from 'axios'
import { getJwt } from '../../redux/actions/authentication'

export function onlyFetchGateways() {
  return fetch('/api/gateways', buildHTTPGetOptions())
}

export function fetchGateways() {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    return fetch('/api/gateways', buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(gates => dispatch({ type: Action.GotGateways, data: gates }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => dispatch({ type: Action.InProgressEnd }))
  }
}

export function fetchGatewayStatus(params: any) {
  return (dispatch: Dispatch, getState: any) => {
    const { status } = getState()
    const url = new URL('/api/gateway/status', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return axios(url.toString(), {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })
      .then((statusResponse: any) => {
        if (status.Status === statusResponse.data.Status) {
          return
        } else {
          dispatch({ type: Action.GotStatus, data: statusResponse.data })
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchGatewayReboot(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/gateway/reboot', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const onlyFetchGlobalSymbolMap = onlyFetchBuilder('/api/globalSymbolMap', { Page: true, Count: true, Search: true })
export const fetchGlobalSymbolMap = fetchBuilder(onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)
export const modifyGlobalSymbolMap = modifyBuilder('/api/globalSymbolMap', onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)
export const deleteGlobalSymbolMap = modifyBuilder('/api/globalSymbolMap', onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)

export const onlyFetchPlatforms = onlyFetchBuilder('/api/platforms')
export const fetchPlatforms = fetchBuilder(onlyFetchPlatforms, Action.GotPlatforms)

export function fetchAddPlatform1(params: any, data: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/add', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    if (data.settings.StartTime) {
      data.settings.StartTime = data.settings.StartTime.getTime() - moment(data.settings.StartTime).startOf('day').toDate().getTime()
    }
    if (data.settings.EndTime) {
      data.settings.EndTime = data.settings.EndTime.getTime() - moment(data.settings.EndTime).startOf('day').toDate().getTime()
    }

    const platform = {
      ...data.settings,
      ...data.common,
      ConnectionString: data.connectionString,
      CoverageConnectionString: data.common.Type === 'fixapi' ? data.covConnectionString : undefined,
      RMQ: data.common.Type === 'mt4' ? data.rmq : undefined,
    }
    return fetch(url.toString(), buildHTTPPostOptions(platform))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchAddPlatform(arg: any) {
  return (dispatch: Dispatch) => {
    const { params, body } = arg
    const url = new URL('/api/platforms/add', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function deletePlatform(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/delete', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    const body = {
      Name: params.Name,
    }
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          if (result.Status === 1) {
            throwWarnMessage(`platform.status.${result.Status}`)
          } else {
            return new Promise((resolve, reject) => reject(new Error(`platform.status.${result.Status}`)))
          }
        }
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function togglePlatform(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/setEnable', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    const body = {
      Name: params.Name,
      Flag: params.Enabled,
    }
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchPlatformReloadManApi(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/reloadManApi', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Name)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchPlatformReloadGateApi(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/reloadGateApi', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Name)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const modifyPlatform = modifyBuilder('/api/platforms', onlyFetchPlatforms, Action.GotPlatforms)

export const onlyFetchPlatformSymbolMap = onlyFetchBuilder('/api/platform/symbolMap', { Platform: true })
export const fetchPlatformSymbolMap = fetchBuilder(onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap)

export const fetchPlatformSymbolMapDelete = modifyBuilder('/api/platform/symbolMap', onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap, {
  Platform: true,
})
export const fetchPlatformSymbolMapAdd = modifyBuilder('/api/platform/symbolMap', onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap, { Platform: true })

export const onlyFetchPlatformSessions = onlyFetchBuilder('/api/platform/sessions', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformSessions = fetchBuilder(onlyFetchPlatformSessions, Action.GotPlatformSessions)
export const fetchPlatformSessionsUpdate = modifyBuilder(
  '/api/platform/sessions',
  onlyFetchPlatformSessions,
  Action.GotPlatformSessions,
  { Platform: true },
  (status: number) => {
    return new Promise((resove, reject) => reject(new Error('sessions.same-session-name')))
  },
)

export const onlyFetchPlatformAccountsNames = onlyFetchBuilder('/api/platform/accounts/names', { Platform: true })
export const onlyFetchPlatformGroupsNames = onlyFetchBuilder('/api/platform/groups/names', { Platform: true })

export const onlyFetchPlatformAccounts = onlyFetchBuilder('/api/platform/accounts', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformAccounts = fetchBuilder(onlyFetchPlatformAccounts, Action.GotFixAccounts)
export const fetchPlatformAccountsUpdate = modifyBuilder('/api/platform/accounts', onlyFetchPlatformAccounts, Action.GotFixAccounts, { Platform: true })

export const onlyFetchPlatformMarginInfo = onlyFetchBuilder('/api/platform/marginInfo', { Platform: true, Page: true, Count: true })
export const fetchPlatformGotFixMarginInfo = fetchBuilder(onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo)
export const fetchPlatformGotFixMarginInfoUpdate = modifyBuilder('/api/platform/marginInfo', onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo, {
  Platform: true,
})
export const onlyFetchMarginInfoDepositHistory = onlyFetchBuilder('/api/platform/marginInfo/depositHistory', { Platform: true, Login: true })
export const fetchMarginInfoUpdate = modifyBuilder('/api/platform/marginInfo', onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo, {
  Platform: true,
  Login: true,
})

export const onlyFetchPlatformSwaps = onlyFetchBuilder('/api/platform/swaps', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformSwaps = fetchBuilder(onlyFetchPlatformSwaps, Action.GotFixSwaps)
export const fetchPlatformSwapsUpdate = modifyBuilder('/api/platform/swaps', onlyFetchPlatformSwaps, Action.GotFixSwaps, { Platform: true })

export const onlyFetchPlatformFixSymbols = onlyFetchBuilder('/api/platform/fixSymbolMap', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformFixSymbols = fetchBuilder(onlyFetchPlatformFixSymbols, Action.GotFixSymbolMap)
export const fetchPlatformFixSymbolsUpdate = modifyBuilder('/api/platform/fixSymbolMap', onlyFetchPlatformFixSymbols, Action.GotFixSymbolMap, {
  Platform: true,
})

export const onlyFetchPlatformExtSymbols = onlyFetchBuilder('/api/platform/extSymbolMap', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformExtSymbols = fetchBuilder(onlyFetchPlatformExtSymbols, Action.GotExtSymbolMap)
export const fetchPlatformExtSymbolsUpdate = modifyBuilder('/api/platform/extSymbolMap', onlyFetchPlatformExtSymbols, Action.GotExtSymbolMap, {
  Platform: true,
})

export const onlyFetchUsers = onlyFetchBuilder('/api/users', { Page: true, Count: true, Search: true })
export const fetchUsers = fetchBuilder(onlyFetchUsers, Action.GotUsers)
export const fetchUsersUpdate = modifyBuilder('/api/users', onlyFetchUsers, Action.GotUsers)
