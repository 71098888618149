import { buildMultiselectOptionsFromArray, buildSelectOption } from '../utils/multiselect-utils'
import { IMultiSelectItem } from './multiselect'
import { LpSession } from './platforms'
import { UserRoles } from '../redux/actions/authentication'
import * as yup from 'yup'
import { expToNum } from '../utils/num-utils'

interface IPool {
  Name: string
  Type: string
}

export class GatewayPlatformEntity {
  public Name: string
  public Type: string
  public Symbols: any
  public Sessions: string[]

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.Type = item.Type
      this.Symbols = buildMultiselectOptionsFromArray(item.Symbols)
      this.Sessions = item.Sessions ? item.Sessions : []
    } else {
      this.Name = ''
      this.Type = ''
      this.Symbols = []
      this.Sessions = []
    }
  }
}

export class GatewayEntity {
  Name: string
  Platforms: GatewayPlatformEntity[]
  Pools: IPool[]
  Lps: IMultiSelectItem[]
  Processors: any[]
  ExposureCurrencies: IMultiSelectItem[]
  EnablePriceOption: boolean;

  [key: string]: any

  constructor(item?: GatewayEntity) {
    if (item) {
      this.Name = item.Name
      this.Platforms = item.Platforms.map((platform: any) => new GatewayPlatformEntity(platform))
      this.Pools = item.Pools
      this.Lps = item.Lps ? buildMultiselectOptionsFromArray(item.Lps) : []
      this.Processors = item.Processors
      this.ExposureCurrencies = item.ExposureCurrencies ? buildMultiselectOptionsFromArray(item.ExposureCurrencies) : []
      this.EnablePriceOption = item.EnablePriceOption ?? true
    } else {
      this.Name = ''
      this.Platforms = []
      this.Pools = []
      this.Lps = []
      this.Processors = []
      this.ExposureCurrencies = []
      this.EnablePriceOption = false
    }
  }
}

export class GatewaySettingsEntity {
  Name: string
  ServerApiAddress: string
  ClientApiAddress: string
  RestoreMaxPerSec: number
  LimitsCheckTimeout: number
  ImmediateOrdersCheckTimeout: number
  ExposureCurrencies: IMultiSelectItem[];

  [key: string]: any

  static schema = {
    Name: yup.string().required(),
    ServerApiAddress: yup.string().required(),
    ClientApiAddress: yup.string().required(),
    RestoreMaxPerSec: yup.number().required(),
    LimitsCheckTimeout: yup.number().required(),
    ImmediateOrdersCheckTimeout: yup.number().required(),
    ExposureCurrencies: yup.array().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.ServerApiAddress = item.ServerApiAddress
      this.ClientApiAddress = item.ClientApiAddress
      this.RestoreMaxPerSec = item.RestoreMaxPerSec
      this.LimitsCheckTimeout = item.LimitsCheckTimeout
      this.ImmediateOrdersCheckTimeout = item.ImmediateOrdersCheckTimeout
      this.ExposureCurrencies = item.ExposureCurrencies ? buildMultiselectOptionsFromArray(item.ExposureCurrencies) : []
    } else {
      this.Name = ''
      this.ServerApiAddress = ''
      this.ClientApiAddress = ''
      this.RestoreMaxPerSec = 0
      this.LimitsCheckTimeout = 0
      this.ImmediateOrdersCheckTimeout = 0
      this.ExposureCurrencies = []
    }
  }
}

export class SmtpEntity {
  SmtpServerHost: string
  SmtpServerPort: number
  UseSsl: boolean
  SendOnRestart: boolean;

  [key: string]: any

  static schema = {
    SmtpServerHost: yup.string().required(),
    SmtpServerPort: yup.number().required(),
    UseSsl: yup.boolean().required(),
    SendOnRestart: yup.boolean().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.SmtpServerHost = item.SmtpServerHost
      this.SmtpServerPort = item.SmtpServerPort
      this.UseSsl = item.UseSsl
      this.SendOnRestart = item.SendOnRestart
    } else {
      this.SmtpServerHost = 'smtp.gmail.com'
      this.SmtpServerPort = 587
      this.UseSsl = true
      this.SendOnRestart = false
    }
  }
}

export class LpSymbol {
  public Symbol: string
  public LpSymbol: string
  public ContractMultiplier: string
  public DealCurrency: string
  public Type: string
  public error: number

  constructor(item?: any) {
    if (item) {
      this.Symbol = item.Symbol
      this.LpSymbol = item.LpSymbol
      this.ContractMultiplier = expToNum(item.ContractMultiplier)
      this.DealCurrency = item.DealCurrency
      this.Type = item.Type
      this.error = item.error
    } else {
      this.Symbol = ''
      this.LpSymbol = ''
      this.ContractMultiplier = ''
      this.DealCurrency = ''
      this.Type = ''
      this.error = 0
    }
  }
}

export class LpEntity {
  Name: string
  Type: string
  Server: any
  Login: any
  Password: any
  FeedingSession: LpSession
  ExecutionSession: LpSession

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.Type = item.Type
      this.Server = item.Server
      this.Login = item.Login
      this.Password = item.Password
      this.FeedingSession = new LpSession(item.FeedingSession)
      this.ExecutionSession = new LpSession(item.ExecutionSession)
    } else {
      this.Name = ''
      this.Type = 'LMAX'
      this.FeedingSession = new LpSession()
      this.ExecutionSession = new LpSession()
    }
  }
}

export class LpAlpaca {
  Name: string
  Type: string
  ApiKey: string
  ApiSecret: string
  Live: boolean

  constructor(item?: any) {
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? 'AlpacaMarkets'
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.Live = item?.Live ?? false
  }
}

export class Binance {
  Name: string
  Type: string
  ApiKey: string
  ApiSecret: string
  LogPath: string
  AllowedCancellationsForUser: any
  AllowedCancellationsForSession: any
  CancellationRatingResetTimeout: number
  ReconnectTimeout: number
  TasksLimit: number
  DepthLevels: number
  QuotesUpdateInterval: number
  StreamLastPrices: boolean
  EnableFeederLogging: boolean
  Leverage: number
  WebServiceUrl?: string

  constructor(item?: any) {
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? ''
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.LogPath = item?.LogPath ?? ''
    this.AllowedCancellationsForSession = item?.AllowedCancellationsForSession ?? 25
    this.AllowedCancellationsForUser = item?.AllowedCancellationsForUser ?? 5
    this.CancellationRatingResetTimeout = item?.CancellationRatingResetTimeout ?? 10000
    this.ReconnectTimeout = item?.ReconnectTimeout ?? 60000
    this.TasksLimit = item?.TasksLimit ?? 100
    this.DepthLevels = item?.DepthLevels ?? 10
    this.QuotesUpdateInterval = item?.QuotesUpdateInterval ?? 100
    this.StreamLastPrices = item?.StreamLastPrices ?? true
    this.EnableFeederLogging = item.EnableFeederLogging ?? false
    this.Leverage = item.Leverage ?? 100
    this.WebServiceUrl = item?.WebServiceUrl ?? ''
  }
}

export class isHedging {
  Mask: string
  Threshold: string

  constructor(item?: any) {
    this.Mask = item?.Mask ?? ''
    this.Threshold = item?.Threshold ?? ''
  }
}

export class HedgingBBook {
  Name: string
  Type: string
  Thresholds: any
  AggregationPool: string

  constructor(item?: any) {
    this.AggregationPool = item?.AggregationPool ?? ''
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? 'BbookHedged'
    this.Thresholds = item?.Thresholds ?? []
  }
}

export class Laverate {
  ApiKey: string
  ApiSecret: string
  LogPath: string
  ReconnectTimeout: number
  EnableFeederLogging: boolean
  WebServiceUrl?: string

  constructor(item?: any) {
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.LogPath = item?.LogPath ?? ''
    this.ReconnectTimeout = item?.ReconnectTimeout ?? 100000
    this.EnableFeederLogging = item?.EnableFeederLogging ?? false
    this.WebServiceUrl = item?.WebServiceUrl ?? ''
  }
}

export class GlobalSymbol {
  Id: number
  Symbol: string
  LpSymbols: any[]
  PlatformSymbols: any[]
  Description: string

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Symbol = item.Symbol
      this.LpSymbols = item.LpSymbols
      this.PlatformSymbols = item.PlatformSymbols
      this.Description = item.Description
    } else {
      this.Id = -1
      this.Symbol = ''
      this.LpSymbols = []
      this.PlatformSymbols = []
      this.Description = ''
    }
  }
}

export class UserEntity {
  Id: number
  Username: string
  Password: string
  Roles: UserRoles[]
  Description: string
  AllowedAddress: IMultiSelectItem[]
  UserType: any

  parseAllowedAddress(address: string | IMultiSelectItem[]): IMultiSelectItem[] {
    if (Array.isArray(address)) {
      return [...address]
    }

    if (typeof address === 'string') {
      const addressArray = address.split(';')
      const newAddress = addressArray.map((el: any) => buildSelectOption(el))
      return newAddress
    }

    return [buildSelectOption('*')]
  }

  constructor(item?: any) {
    if (item) {
      this.UserType = item.UserType
      this.Id = item.Id
      this.Username = item.Username
      this.Password = item.Password
      this.Roles = item.Roles
      this.Description = item.Description
      this.AllowedAddress = this.parseAllowedAddress(item.AllowedAddress)
    } else {
      this.UserType = 'custom'
      this.Id = 0
      this.Username = ''
      this.Password = ''
      this.Roles = []
      this.Description = ''
      this.AllowedAddress = [{ label: '*', value: '*' }]
    }
  }
}
