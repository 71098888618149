import { faBars } from '@fortawesome/free-solid-svg-icons'
import { AppLoginPage, AppNavbar, AppSideBar } from '@t4b/core/lib'
import { Languages } from '@t4b/core/lib/entity/languages'
import { ILogo } from '@t4b/core/lib/entity/logo'
import Highcharts from 'highcharts'
import axios from 'axios'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import * as React from 'react'
import { RefObject, useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import CurrentTime from './components/CurrentTime'
import GatewayStatus from './components/GatewayStatus'
import ModalContainer from './components/modals/ModalContainer'
import NavUser from './components/NavUser'
import NotEnoughPrivelege from './components/NotEnoughPrivilege'
import PrivateRoute from './components/PrivateRoute'
import ProgressBar from './components/ProgressBar'
import RightbarContainer from './components/rightbar/RightbarContainer'
import { getSidebarItems } from './const/SidebarConst'
import MESSAGES_EN from './i18n/en.json'
import MESSAGES_JP from './i18n/jp.json'
import mainlogo from './img/logo.png'
import { Action } from './redux/actions/actions'
import { fetchSignIn, User } from './redux/actions/authentication'
import { showModal } from './redux/actions/modal-actions'
import { fetchGateways } from './redux/actions/platforms-actions'
import Loader from './components/Loader'
import { removeJwt, removeUser, removeTokenRefresh } from './redux/actions/authentication'

const Summary = React.lazy(() => import('./components/Summary'))
const Exposure = React.lazy(() => import('./components/Exposure'))
const Positions = React.lazy(() => import('./components/Positions'))
const TradingHistory = React.lazy(() => import('./components/TradingHistory'))
const RiskTool = React.lazy(() => import('./components/RiskTool'))
const VolumeTransferPage = React.lazy(() => import('./components/volume-transfer/VolumeTransferPage'))
const FixApiConfigs = React.lazy(() => import('./components/FixApiConfigs'))
const Mt4Configs = React.lazy(() => import('./components/configuration/platforms/Mt4Configs'))
const Mt5Configs = React.lazy(() => import('./components/configuration/platforms/Mt5Configs'))
const ExtApiConfigs = React.lazy(() => import('./components/configuration/platforms/ExtApiConfigs'))
const MtExecConfigs = React.lazy(() => import('./components/configuration/platforms/MtExecConfigs'))
const ExecutionLpRules = React.lazy(() => import('./components/configuration/execution-configuration/ExecutionLpRules'))
const ProcessingRulesTable = React.lazy(() => import('./components/configuration/execution-configuration/ProcessingRulesTable'))
const ExecutionConfiguration = React.lazy(() => import('./components/configuration/ExecutionConfiguration'))
const FeedPlatformSymbolMap = React.lazy(() => import('./components/configuration/feeding-configuration/FeedPlatformSymbolMap'))
const FeedingConfiguration = React.lazy(() => import('./components/configuration/FeedingConfiguration'))
const LpSymbolMaps = React.lazy(() => import('./components/configuration/system/LpSymbolMaps'))
const SystemSettings = React.lazy(() => import('./components/configuration/SystemSettings'))
const Backups = React.lazy(() => import('./components/configuration/Backups'))
const Consolidation = React.lazy(() => import('./components/configuration/Consolidation'))
const ContExec = React.lazy(() => import('./components/configuration/ContExec'))
const DropCopy = React.lazy(() => import('./components/configuration/DropCopy'))
const LpPerformancePage = React.lazy(() => import('./components/lp-performance/LpPerformancePage'))
const MonitoringPage = React.lazy(() => import('./components/monitoring/MonitoringPage'))

const appLogo: ILogo = {
  src: mainlogo,
  height: 48,
  width: 215,
  className: '',
  alt: 'logo',
  href: '#',
}

const APP_LANGS: Languages = {
  en: 'English',
  ja: '日本',
}

const messages: any = {
  en: MESSAGES_EN,
  ja: MESSAGES_JP,
}

function getLanguage(): string {
  return localStorage.getItem('lang') || 'en'
}

export const ScrollContext = React.createContext<any>(null)
export const UserContext = React.createContext<any>(null)

const App: React.FC = () => {
  const [currentLang, onLangChange] = useState(getLanguage())
  const [sidebarHidden, setSidebarHidden] = useState(true)
  const [date, setDate] = useState(new Date().getTime() + 1000 * 60 * 60)
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    // rightbar: { rightbarHidden },
    auth: isAuthenticated,
    ...rest
  } = useSelector((state: any) => state)
  const scrollRef: RefObject<OverlayScrollbarsComponent> = useRef(null)
  // const [, setTimestapm] = useState(true);
  const user = new User()

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchGateways())
    }
  }, [dispatch, isAuthenticated, location.pathname])

  const handleTransitionEnd = () => {
    Highcharts.charts.forEach((chart: any) => {
      if (chart && chart.reflow) {
        chart.reflow()
      }
    })
  }

  const onSignIn = (username: string, password: string, rememberMe: boolean) => {
    fetchSignIn(username, password, () => {
      dispatch({ type: Action.Authorized })
    })
  }

  const onLicenseClick = () => {
    dispatch(showModal('LICENSE', {}))
  }

  const flushGateways = (current: any) => {
    if (current !== window.location.pathname) {
      dispatch({ type: Action.FlushGateways })
    }
  }

  const renderAppLoginPage = () =>
    isAuthenticated ? (
      <Redirect to="/summary" />
    ) : (
      <div className="text-center">
        <AppLoginPage canBeRestored={false} canBeRegistered={false} signInFunc={onSignIn} />
      </div>
    )

  const handleSidebarClick = () => {
    setSidebarHidden(!sidebarHidden)
  }

  const handleScrollStop = React.useMemo(
    () => (event: any) => {
      // setTimestapm(event.timeStamp);
    },
    [],
  )

  const handleLangChange = (lang: any) => {
    localStorage.setItem('lang', lang)
    onLangChange(lang)
  }

  axios.interceptors.request.use(
    response => {
      if (response.method === 'post' && response.data?.token === undefined) {
        removeJwt()
        removeUser()
        removeTokenRefresh()
        dispatch({ type: Action.Unauthorized })
      }
      return response
    },
    error => {
      return error
    },
  )

  const oneHourRemoveJwt = (e: any) => {
    const dateMouse = new Date().getTime()
    if (dateMouse > date) {
      setDate(new Date().getTime() + 1000 * 60 * 60)
    }
    if (isAuthenticated) {
      if (dateMouse > date) {
        setDate(new Date().getTime() + 100000 * 60000 * 60000)
        removeJwt()
        removeUser()
        removeTokenRefresh()
        dispatch({ type: Action.Unauthorized })
      } else {
        setDate(new Date().getTime() + 1000 * 60 * 60)
      }
    }
  }

  return (
    <IntlProvider locale={currentLang} messages={messages[currentLang]}>
      <UserContext.Provider value={{ isAuthenticated }}>
        <div onMouseMove={oneHourRemoveJwt}>
          <AppNavbar
            logo={appLogo}
            i18nLangs={APP_LANGS}
            currentLangKey={currentLang}
            onLangChange={handleLangChange}
            isAuthenticated={isAuthenticated}
            // sidebarHidden={sidebarHidden}
            onSidebarHandlerClick={handleSidebarClick}
            sidebarHidden={sidebarHidden}
            navbarItemsRight={[<GatewayStatus key={1} />, <CurrentTime key={2} />]}
            exitButton={<NavUser />}
          />
          <div className="d-flex flex-row position-relative" style={{ background: '#ecf5fd' }}>
            {isAuthenticated && (
              <AppSideBar
                sidebarItems={getSidebarItems(flushGateways, rest)}
                logo={faBars}
                progressItems={[]}
                sidebarHidden={sidebarHidden}
                currentLocation={location.pathname}
                onTransitionEndCallback={handleTransitionEnd}
                isScrollable={true}
                onLicenceClick={onLicenseClick}
              />
            )}
            <OverlayScrollbarsComponent
              ref={scrollRef}
              style={{ height: 'calc(100vh - 58px)', width: '100%' }}
              options={{
                callbacks: {
                  onScrollStop: handleScrollStop,
                },
              }}
            >
              <ScrollContext.Provider value={(scrollRef && scrollRef.current && scrollRef.current.osInstance()) || {}}>
                <React.Suspense
                  fallback={
                    <div className=" wrapper-loader">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    className="App"
                    style={{
                      padding: '30px',
                      height: 'calc(100vh - 58px)',
                      width: '100%',
                    }}
                  >
                    <ProgressBar className="position-absolute top-0 left-0" />

                    <Switch>
                      <Route path="/login" component={renderAppLoginPage} />
                      <PrivateRoute exact={true} path="/" isAuthenticated={false} component={renderAppLoginPage} />

                      <PrivateRoute exact={true} path="/summary" isAuthenticated={isAuthenticated} component={<Summary />} />
                      <PrivateRoute path="/exposure" isAuthenticated={isAuthenticated} component={<Exposure />} />
                      <PrivateRoute path="/positions" isAuthenticated={isAuthenticated} component={<Positions />} />
                      <PrivateRoute path="/trading-history" isAuthenticated={isAuthenticated} component={<TradingHistory />} />
                      <PrivateRoute path="/risk-tool/:tool" isAuthenticated={isAuthenticated} component={<RiskTool />} />
                      <PrivateRoute path="/volume-transfer/:tool" isAuthenticated={isAuthenticated} component={<VolumeTransferPage />} />
                      <PrivateRoute exact={true} path="/system-settings/platforms/:page" isAuthenticated={isAuthenticated} component={<FixApiConfigs />} />
                      <PrivateRoute exact={true} path="/system-settings/platforms/platform_mt4/:page" isAuthenticated={isAuthenticated} component={<Mt4Configs />} />
                      <PrivateRoute exact={true} path="/system-settings/platforms/platform_mt5/:page" isAuthenticated={isAuthenticated} component={<Mt5Configs />} />
                      <PrivateRoute
                        exact={true}
                        path="/system-settings/platforms/extapi/:page"
                        isAuthenticated={isAuthenticated}
                        component={<ExtApiConfigs />}
                      />
                      <PrivateRoute
                        exact={true}
                        path="/system-settings/platforms/mtexec/:page"
                        isAuthenticated={isAuthenticated}
                        component={<MtExecConfigs />}
                      />
                      <PrivateRoute exact={true} path="/system-settings/lps/:page" isAuthenticated={isAuthenticated} component={<LpSymbolMaps />} />
                      <PrivateRoute exact={true} path="/execution-configuration" isAuthenticated={isAuthenticated} component={<ExecutionConfiguration />} />
                      <PrivateRoute
                        exact={true}
                        path="/execution-configuration/processing-rules"
                        isAuthenticated={isAuthenticated}
                        component={<ProcessingRulesTable />}
                      />
                      <PrivateRoute exact={true} path="/execution-configuration/lps" isAuthenticated={isAuthenticated} component={<ExecutionLpRules />} />
                      <PrivateRoute exact={true} path="/feeding-configuration" isAuthenticated={isAuthenticated} component={<FeedingConfiguration />} />
                      <PrivateRoute
                        exact={true}
                        path="/feeding-configuration/platform"
                        isAuthenticated={isAuthenticated}
                        component={<FeedPlatformSymbolMap />}
                      />
                      <PrivateRoute path="/system-settings/:page" isAuthenticated={isAuthenticated} component={<SystemSettings />} />
                      <PrivateRoute path="/backups/:page" isAuthenticated={isAuthenticated} component={<Backups />} />
                      <PrivateRoute path="/continious-execution/:page" isAuthenticated={isAuthenticated} component={<ContExec />} />
                      <PrivateRoute path="/consolidation/:page" isAuthenticated={isAuthenticated} component={<Consolidation />} />
                      <PrivateRoute path="/drop-copy/:page" isAuthenticated={isAuthenticated} component={<DropCopy />} />
                      <PrivateRoute path="/lp-performance/:page" isAuthenticated={isAuthenticated} component={<LpPerformancePage />} />
                      <PrivateRoute path="/monitoring/:page" isAuthenticated={isAuthenticated} component={<MonitoringPage />} />

                      <Route exact={true} path="/risk-tool" render={() => <Redirect to={user.genLink('/risk-tool')} />} />
                      <Route exact={true} path="/volume-transfer" render={() => <Redirect to={user.genLink('/volume-transfer')} />} />
                      <Route exact={true} path="/backups" render={() => <Redirect to={{ pathname: user.genLink('/backups') }} />} />
                      <Route exact={true} path="/continious-execution" render={() => <Redirect to={user.genLink('/continious-execution')} />} />
                      <Route exact={true} path="/consolidation" render={() => <Redirect to={user.genLink('/consolidation')} />} />
                      <Route exact={true} path="/drop-copy" render={() => <Redirect to={user.genLink('/drop-copy')} />} />
                      <Route exact={true} path="/system-settings" render={() => <Redirect to={user.genLink('/system-settings')} />} />
                      <Route exact={true} path="/lp-performance" render={() => <Redirect to={user.genLink('/lp-performance')} />} />
                      <Route exact={true} path="/monitoring" render={() => <Redirect to={user.genLink('/monitoring')} />} />
                      <Route exact={true} path="/not-enough-privilege" component={NotEnoughPrivelege} />
                    </Switch>
                  </div>
                </React.Suspense>
              </ScrollContext.Provider>
            </OverlayScrollbarsComponent>
            {isAuthenticated && <RightbarContainer />}
          </div>
          <ModalContainer />
          <ToastContainer pauseOnHover={false} />
        </div>
      </UserContext.Provider>
    </IntlProvider>
  )
}

export default App
