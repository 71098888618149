import { fetchBuilder, modifyBuilder, onlyFetchBuilder, buildHTTPGetOptions, checkResponse, fetchGet, processError } from '../../utils/fetch-utils'
import { Dispatch } from 'redux'
import { Action } from './actions'

const onlyFetchGatewaySettings = onlyFetchBuilder('/api/systemSettings/common/gateway')
const onlyFetchDatabaseSettings = onlyFetchBuilder('/api/systemSettings/common/database')
const onlyFetchSmtpSettings = onlyFetchBuilder('/api/systemSettings/common/smtp')

export const fetchGetawaySettings = fetchBuilder(onlyFetchGatewaySettings, Action.GotGateway)
export const fetchDatabaseSettings = fetchBuilder(onlyFetchDatabaseSettings, Action.GotDatabase)
export const fetchSmtpSettings = fetchBuilder(onlyFetchSmtpSettings, Action.GotSmtp)

export const modifyGatewaySettings = modifyBuilder('/api/systemSettings/common/gateway', onlyFetchGatewaySettings, Action.GotGateway)
export const modifyDatabaseSettings = modifyBuilder('/api/systemSettings/common/database', onlyFetchDatabaseSettings, Action.GotDatabase)
export const modifySmtpSettings = modifyBuilder('/api/systemSettings/common/smtp', onlyFetchSmtpSettings, Action.GotSmtp)

export const onlyFetchLps = onlyFetchBuilder('/api/lps')
export const fetchLps = fetchBuilder(onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdate = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)
export const fetchLpsAdd = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)
export const fetchLpsDelete = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddBased = modifyBuilder('/api/lps/AddApiBasedLp', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateBased = modifyBuilder('/api/lps/ModifyApiBasedLp', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddHedgingBBook = modifyBuilder('/api/lps/AddHedgingBBook', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateHedgingBBook = modifyBuilder('/api/lps/ModifyHedgingBBook', onlyFetchLps, Action.GotSysLps)

export const onlyFetchLpTypes = onlyFetchBuilder('/api/lps/types')
export const onlyFetchLpPool = onlyFetchBuilder('/api/hedgedBBook/getAvaliablePools')

export const onlyFetchBinanceSelect = onlyFetchBuilder('/api/lps/BinanceConfigAllowedValues')
export const onlyFetchBinanceSelectRedux = fetchBuilder(onlyFetchBinanceSelect, Action.GotSysLpBinanceSelect)

export const fetchLpPool = fetchBuilder(onlyFetchLpPool, Action.GotLPPool)
export const fetchLpTypes = fetchBuilder(onlyFetchLpTypes, Action.GotLPTypes)

export const onlyFetchLpSymbolMap = onlyFetchBuilder('/api/lp/symbolMap', {
  Lp: true,
  Page: true,
  Count: true,
  Search: true,
})
export const fetchLpSymbolMap = fetchBuilder(onlyFetchLpSymbolMap, Action.GotSysLpSymbolMap)
export const fetchLpSymbolMapUpdate = modifyBuilder('/api/lp/symbolMap', onlyFetchLpSymbolMap, Action.GotSysLpSymbolMap, { Lp: true })

export const onlyFetchLpSymbols = onlyFetchBuilder('/api/lp/symbols', {
  Lp: true,
})

export const onlyFetchGlobalSymbols = onlyFetchBuilder('/api/globalSymbols')

export function fetchHedgedBbookVolume(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/hedgedBBook/currentVolumes', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('hbbookName', window.location.search.split('%20').join(' ').slice(4))
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: Action.GotHedgedBbookVolume, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchHedgedBbookHistory(setLoading: (isLoading: boolean) => void, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet('/api/hedgedBBook/history', ...queryParams)
      dispatch({ type: Action.GotHedgedBbookHistory, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}
