import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildInfoItems, buildVolumeDistribution, infoDirection, infoMs, infoPrice, infoProfit, infoTime } from '../../utils/info-item-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const MT5Deals: React.FC<IRightbar> = ({
  data: {
    Gateway,
    Platform,
    position: { Deal },
  },
}) => {
  const dispatch = useDispatch()
  const [position, setPosition] = useState<any>({})

  useEffect(() => {
    const url = new URL('/api/deal', window.location.origin)
    url.searchParams.set('gateway', Gateway)
    url.searchParams.set('platform', Platform)
    url.searchParams.set('id', Deal)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [dispatch, Gateway, Platform, Deal])

  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems(['ExternalId', 'ContractSize', 'Dealer', 'Comment'], position, 'mt5deals')}</div>
      <div className="col-6">{buildInfoItems(['Digits', 'UserName', 'UserGroup'], position, 'mt5deals')}</div>
    </div>
  )

  return (
    <>
      <Card>
        <Card.Header
          style={{
            color: 'rgb(141, 171, 196)',
            fontWeight: 500,
            fontSize: '13px',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="deal-details" />
        </Card.Header>
        <Card.Body>
          <div className="row mb-3">
            <div className="col-6">
              {buildInfoItems(
                [
                  'Deal',
                  'Login',
                  '',
                  infoDirection('Action'),
                  infoPrice('OpenPrice'),
                  '',
                  '',
                  'GatewayPrice',
                  infoTime('Time'),
                  '',
                  infoProfit('Profit'),
                  'BrokerMarkup',
                  '',
                  infoMs('LpExecutionTime'),
                  infoMs('FullExecutionTime'),
                  'LpPriceSlippage',
                  'PlatformPriceSlippage',
                ],
                position,
                'mt5deals',
              )}
            </div>
            <div className="col-6">
              {buildInfoItems(
                [
                  'PositionId',
                  'Symbol',
                  '',
                  'Volume',
                  'LotVolume',
                  infoPrice('Price'),
                  '',
                  'TpPriceAdjustment',
                  '',
                  '',
                  infoProfit('BrokerProfit'),
                  '',
                  '',
                  'TpMarkup',
                  'TotalMarkup',
                  infoProfit('TpBrokerProfit'),
                  infoProfit('TotalBrokerProfit'),
                ],
                position,
                'mt5deals',
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      <AppAccordion
        item={{
          title: <FormattedMessage id="additional" />,
          item: additionalComponent,
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="volume-distribution" />,
          item: buildVolumeDistribution(position.Distribution),
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
      />
    </>
  )
}

export default MT5Deals
