import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Day } from '../../entity/configuration'
import { Swap } from '../../entity/platforms'
import { fetchPlatformSwapsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, mselectInput, textInput, timespanInput } from '../../utils/controls'
import { buildMultiselectOptionsFromEnum, optionsToStrings } from '../../utils/multiselect-utils'
import { useFormValidation } from '../../hooks/useFormValidation'
import { AppAccordion } from '@t4b/core/lib'
import * as yup from 'yup'
import { IRightbar } from './rightbar-types'

function prepareObj(item: any) {
  item.Days = optionsToStrings(item.Days)
  return item
}

const schema = {
  Days: yup.array().required(),
  Time: yup.number().required(),
  Multiplier: yup.number().required(),
}

const SwapsRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new Swap(item), schema)
  const dispatch = useDispatch()

  const inputs = buildControlsExtTwoPerLine(
    [mselectInput('Days', buildMultiselectOptionsFromEnum(Day)), timespanInput('Time'), textInput('Multiplier')],
    inputState,
    setInputState,
    'swaps',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (type === 'add') {
      dispatch(
        fetchPlatformSwapsUpdate({
          action: type,
          params: { ...params },
          body: prepareObj({ ...inputState }),
        }),
      )
    } else {
      dispatch(
        fetchPlatformSwapsUpdate({
          action: type,
          params: { ...params },
          body: {
            OldSwap: prepareObj({ ...item }),
            NewSwap: prepareObj({ ...inputState }),
          },
        }),
      )
    }
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`swaps.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default SwapsRightbar
