export const START_OF_DAY: number = 0
export const END_OF_DAY: number = 86399000

export function localAsUTC(date: Date | undefined): Date {
  if (date instanceof Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  }
  return new Date(0)
}

export function UTCAsLocal(date: Date): Date {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}
