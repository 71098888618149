import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { fetchPlatformAccountsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const schema = {
  Login: yup.string().required(),
  Currency: yup.string().required(),
  Group: yup.string().required(),
  Leverage: yup.number().moreThan(0).required(),
  StopOut: yup.number().min(0).required(),
}

const AccountsRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, schema)
  const dispatch = useDispatch()

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('Login').disabled(type === 'modify'),
      textInput('Currency').disabled(type === 'modify'),
      textInput('Group'),
      textInput('Leverage'),
      textInput('StopOut'),
      checkboxInput('MarginOnActivate'),
    ],
    inputState,
    setInputState,
    'accounts',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchPlatformAccountsUpdate({
        action: type,
        params: { ...params },
        body: inputState,
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`accounts.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default AccountsRightbar
