import * as yup from 'yup'
import { StringSchema, NumberSchema } from 'yup'

export function transformEmptyString(value: any, originalValue: any): any {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

export function transformEmptyStringMaxLength(value: any, originalValue: any): any {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }

  if (originalValue.split('.').length === 2) {
    const [one, two] = originalValue.split('.')
    return one?.length <= 10 && two?.length <= 8 ? value : ''
  } else {
    return originalValue.length <= 10 ? value : ''
  }
}

export function portSchema(): NumberSchema<number | null> {
  return yup.number().moreThan(0).lessThan(65536).integer().transform(transformEmptyString).nullable().required()
}

export function passwordSchema(type?: string): StringSchema<any> {
  if (type) {
    return type === 'add' || type === 'clone' ? yup.string().required() : yup.string().notRequired().nullable()
  }
  return yup.string().required()
}

export function rmqSchema(type: string) {
  return {
    RMQHost: yup.string().required(),
    RMQPort: portSchema(),
    RMQVirtualHost: yup.string().required(),
    RMQLogin: yup.string().required(),
    RMQPassword: passwordSchema(type),
    RMQExchange: yup.string().required(),
    RMQQueue: yup.string().required(),
  }
}

export function percentSchema(): NumberSchema<number | null> {
  return yup.number().min(0).max(100).transform(transformEmptyString).nullable().required()
}

export function folderNameSchema(): StringSchema {
  return (
    yup
      .string()
      // eslint-disable-next-line no-control-regex
      .matches(/^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^<>:"/\\|?*\x00-\x1F]*[^<>:"/\\|?*\x00-\x1F.]$/)
      .max(255)
      .required()
  )
}

export function newSchemaName() {
  return yup
    .string()
    .matches(/(?!^ |.* $)^[a-zA-Z0-9 .,()\\[\]'&quot%_#+-]*$/gi)
    .max(255)
    .required()
}

export function routingRuleNameSchema(): StringSchema {
  return (
    yup
      .string()
      // eslint-disable-next-line no-control-regex
      .matches(/^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^<>"/\\|?*\x00-\x1F]*[^<>"/\\|?*\x00-\x1F.]$/)
      .max(255)
      .required()
  )
}

export function loginsSchema(): StringSchema {
  return yup
    .string()
    .matches(/^[0-9*!,]+$/)
    .required()
}
