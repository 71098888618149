import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AggregationPoolEntity, commissionTypes, ProcessingRuleEntity, ProcessingRuleItem, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { useTranslate } from '../../hooks/useTranslate'
import { addProcessingRule, changeProcessingRule, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, commissionTypesConverter, roundTypesConverter, selectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const ProcessingRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item } }) => {
  const starRule = item.Rules[item.Rules.length - 1] || new ProcessingRuleItem()
  const [common, setCommon, commonTouched, setCommonTouched, commonErrors, isCommonValid] = useFormValidation(
    new ProcessingRuleEntity(item),
    ProcessingRuleEntity.schema,
  )

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ProcessingRuleItem(starRule), ProcessingRuleItem.schema)

  const dispatch = useDispatch()
  const { processingRules, aggregationPools } = useSelector((state: RootState) => state.executionConfiguration)
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const translate = useTranslate()

  const handleSave = () => {
    if (!isValid() || !isCommonValid()) {
      return
    }
    const newRule = new ProcessingRuleEntity({
      ...item,
      ...common,
      Rules: [...item.Rules.slice(0, item.Rules.length - 1), new ProcessingRuleItem(inputState)],
    })
    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRule(newRule))
    } else {
      dispatch(changeProcessingRule(newRule))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const alreadyExist = () => {
    if (type === 'add' && processingRules.find((rule: ProcessingRuleEntity) => rule.Name === common.Name)) {
      commonErrors.Name = true
      return 'processing-rule.exists'
    }
    return ''
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name')
              .disabled(type === 'modify')
              .errorMessage(alreadyExist()),
            selectInput('LpPool', (aggregationPools && aggregationPools.map((elem: AggregationPoolEntity) => elem.Name)) || []),
          ],
          common,
          setCommon,
          'processing-rule',
          commonTouched,
          setCommonTouched,
          commonErrors,
        )}
        {buildControlsExtTwoPerLine(
          [
            textInput('MarkupFrom'),
            textInput('MarkupTo'),
            textInput('LimitMarkupFrom'),
            textInput('LimitMarkupTo'),
            selectInput('RoundType').optionItems(roundTypes).converter(roundTypesConverter(translate)),
            checkboxInput('TakePositiveSlippage').skipWhen(!gateway.EnablePriceOption),
            textInput('Commission'),
            selectInput('CommissionType').optionItems(commissionTypes).converter(commissionTypesConverter(translate)),
            textInput('DelayFrom'),
            textInput('DelayTo'),
            checkboxInput('AllowContinuousExecutionForFOK'),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleRightbar
