import React from 'react'
import {
  faArrowAltCircleLeft,
  faBalanceScale,
  faCalculator,
  faChartBar,
  faChartLine,
  faCogs,
  faDesktop,
  faDownload,
  faExchangeAlt,
  faFileInvoiceDollar,
  faPaste,
  faRandom,
  faRetweet,
  faSlidersH,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { User } from '../redux/actions/authentication'
import { AppSideBarItem, IconItem } from '@t4b/core'
import { FormattedMessage } from 'react-intl'

export function getSidebarItems(action: any, { executionConfiguration, feedingConfiguration }: any): AppSideBarItem[] {
  const user = new User()
  const sidebarItems = [
    {
      translateKey: 'sidebar.summary',
      tooltipKey: <FormattedMessage id="sidebar.summary" />,
      isExternal: false,
      link: '/summary',
      icon: new IconItem('48px', faCalculator, '1x', '10px', 'rgb(64, 196, 104)'),
      action,
    },
    {
      translateKey: 'sidebar.exposure',
      tooltipKey: <FormattedMessage id="sidebar.exposure" />,
      isExternal: false,
      link: '/exposure',
      icon: new IconItem('48px', faChartBar, '1x', '10px', 'rgb(64, 196, 104)'),
      action,
    },
    {
      translateKey: 'sidebar.positions',
      tooltipKey: <FormattedMessage id="sidebar.positions" />,
      isExternal: false,
      link: '/positions',
      icon: new IconItem('48px', faPaste, '1x', '10px', 'rgb(64, 196, 104)'),
      action,
    },
    {
      translateKey: 'sidebar.history',
      tooltipKey: <FormattedMessage id="sidebar.history" />,
      isExternal: false,
      link: '/trading-history',
      icon: new IconItem('48px', faFileInvoiceDollar, '1x', '10px', 'rgb(64, 196, 104)'),
      action,
    },
    {
      translateKey: 'sidebar.risk-tool',
      tooltipKey: <FormattedMessage id="sidebar.risk-tool" />,
      isExternal: false,
      link: user.genLink('/risk-tool'),
      icon: new IconItem('48px', faRandom, '1x', '10px', '#2b778d'),
      action,
    },
    {
      translateKey: 'sidebar.volume-transfer',
      tooltipKey: <FormattedMessage id="sidebar.volume-transfer" />,
      isExternal: false,
      link: user.genLink('/volume-transfer'),
      icon: new IconItem('48px', faExchangeAlt, '1x', '10px', '#2b778d'),
      action,
    },
    {
      translateKey: 'sidebar.execution-configuration',
      tooltipKey: <FormattedMessage id="sidebar.execution-configuration" />,
      isExternal: false,
      link: '/execution-configuration',
      icon: new IconItem('48px', faSlidersH, '1x', '10px', 'rgba(171, 125, 246, 0.2)'),
      action,
      notification: executionConfiguration.noChanges ? null : { text: <FormattedMessage id="changes-exists" /> },
    },
    {
      translateKey: 'sidebar.feeding-configuration',
      tooltipKey: <FormattedMessage id="sidebar.feeding-configuration" />,
      isExternal: false,
      link: '/feeding-configuration',
      icon: new IconItem('48px', faChartLine, '1x', '10px', 'rgba(171, 125, 246, 0.2)'),
      action,
      notification: feedingConfiguration.noChanges ? null : { text: <FormattedMessage id="changes-exists" /> },
    },
    {
      translateKey: 'sidebar.continious-execution',
      tooltipKey: <FormattedMessage id="sidebar.continious-execution" />,
      isExternal: false,
      link: user.genLink('/continious-execution'),
      icon: new IconItem('48px', faRetweet, '1x', '10px', '#6fc1bc'),
      action,
    },
    {
      translateKey: 'sidebar.consolidation',
      tooltipKey: <FormattedMessage id="sidebar.consolidation" />,
      isExternal: false,
      link: user.genLink('/consolidation'),
      icon: new IconItem('48px', faBalanceScale, '1x', '10px', '#6fc1bc'),
      action,
    },
    {
      translateKey: 'sidebar.backups',
      tooltipKey: <FormattedMessage id="sidebar.backups" />,
      isExternal: false,
      link: user.genLink('/backups'),
      icon: new IconItem('48px', faDownload, '1x', '10px', '#8C7147'),
      action,
    },
    {
      translateKey: 'sidebar.drop-copy',
      tooltipKey: <FormattedMessage id="sidebar.drop-copy" />,
      isExternal: false,
      link: user.genLink('/drop-copy'),
      icon: new IconItem('48px', faArrowAltCircleLeft, '1x', '10px', '#8C7147'),
      action,
    },
    {
      translateKey: 'sidebar.lp-performance',
      tooltipKey: <FormattedMessage id="sidebar.lp-performance" />,
      isExternal: false,
      link: user.genLink('/lp-performance'),
      icon: new IconItem('48px', faTachometerAlt, '1x', '10px', '#8C7147'),
      action,
    },
    {
      translateKey: 'sidebar.monitoring',
      tooltipKey: <FormattedMessage id="sidebar.monitoring" />,
      isExternal: false,
      link: user.genLink('/monitoring'),
      icon: new IconItem('48px', faDesktop, '1x', '10px', '#8C7147'),
      action,
    },
    {
      translateKey: 'sidebar.system-settings',
      tooltipKey: <FormattedMessage id="sidebar.system-settings" />,
      isExternal: false,
      link: user.genLink('/system-settings'),
      icon: new IconItem('48px', faCogs, '1x', '10px', '#8C7147'),
      action,
    },
  ]

  return sidebarItems.filter((item: AppSideBarItem) => user.hasAccess(item.link))
}
