import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, selectInput, translator } from '../../utils/controls'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { onlyFetchFeeding } from '../../redux/actions/feeding-actions'
import { processError } from '../../utils/fetch-utils'
import { FeedPlatformEntity, FeedPlatformRuleEntity } from '../../entity/feeding'
import { ProcessingRuleItem } from '../../entity/configuration'
import { addProcessingRuleItems, executionConfigurationChanged, getProcessingRuleItems } from '../../redux/actions/execution/execution-actions'
import { onlyFetchGlobalSymbolMap } from '../../redux/actions/platforms-actions'
import { useTranslate } from '../../hooks/useTranslate'

interface IImportProcessingRulesRightbar {
  data: any
}

enum ImportType {
  UseBid = 'UseBid',
  UseAsk = 'UseAsk',
  UseMin = 'UseMin',
  UseMax = 'UseMax',
  UseAvg = 'UseAvg',
}

const initialState = {
  FeedPlatform: '',
  ImportType: ImportType.UseBid,
  SetMarketMarkup: false,
  SetLimitMarkup: false,
}

const ImportProcessingRulesRightbar: React.FC<IImportProcessingRulesRightbar> = props => {
  const { item, params } = props.data
  const [inputState, setInputState] = useState<any>(initialState)
  const [feedPlatforms, setFeedPlatforms] = useState<FeedPlatformEntity[]>([])
  // const [globalSymbols, setGlobalSymbols] = useState<any>([])
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: any) => state.gateways)
  const translate = useTranslate()

  useEffect(() => {
    onlyFetchFeeding({ Gateway: gateway.Name })
      .then((data: any) => {
        setFeedPlatforms(data.FeedPlatforms.map((item: any) => new FeedPlatformEntity(item)))
        setInputState({
          ...inputState,
          FeedPlatform: data?.FeedPlatforms?.[0].Name,
        })
      })
      .catch((error: Error) => processError(error, dispatch))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onlyFetchGlobalSymbolMap({
      Gateway: gateway.Name,
      Page: 1,
      Count: 2147483647,
    })
      .then((data: any) => {
        // setGlobalSymbols(data.Data)
      })
      .catch((error: Error) => processError(error, dispatch))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const val = (rule: FeedPlatformRuleEntity): number => {
    const { ImportType: importType } = inputState

    if (importType === ImportType.UseAsk) {
      return rule.MarkupAsk
    }

    if (importType === ImportType.UseBid) {
      return rule.MarkupBid
    }

    if (importType === ImportType.UseMin) {
      return Math.min(rule.MarkupAsk, rule.MarkupBid)
    }

    if (importType === ImportType.UseMax) {
      return Math.max(rule.MarkupAsk, rule.MarkupBid)
    }

    if (importType === ImportType.UseAvg) {
      return (rule.MarkupAsk + rule.MarkupBid) / 2
    }

    return 0
  }

  // const mapPlatformToGlobal = (platform: string, platformSymbol: string): string => {
  //   const symbol = globalSymbols.find((globalSymbol: any) => {
  //     const platformSymbols = globalSymbol.PlatformSymbols.find((item: any) => item.Platform === platform)
  //     if (platformSymbols) {
  //       if (platformSymbols.Symbol.find((symbolName: string) => symbolName === platformSymbol)) {
  //         return true
  //       }
  //     }
  //     return false
  //   })
  //   if (symbol) {
  //     return symbol.Symbol
  //   }
  //   return platformSymbol
  // }

  const handleLoad = () => {
    const platform = feedPlatforms.find((item: FeedPlatformEntity) => item.Name === inputState.FeedPlatform)

    if (platform) {
      const rules = platform.Rules.map((rule: FeedPlatformRuleEntity) => {
        const newItem = new ProcessingRuleItem(item)

        newItem.Symbols = optionsToStrings(rule.Symbols).join(',')

        if (inputState.SetMarketMarkup) {
          newItem.MarkupFrom = val(rule)
          newItem.MarkupTo = val(rule)
        }

        if (inputState.SetLimitMarkup) {
          newItem.LimitMarkupFrom = val(rule)
          newItem.LimitMarkupTo = val(rule)
        }
        return newItem
      })

      dispatch(addProcessingRuleItems({ rules, params }))
      dispatch(getProcessingRuleItems({ ...params }))
      dispatch(executionConfigurationChanged())
    }
    dispatch(hideRightBar())
  }

  const makePlatformName = (option: string) => {
    const platform = feedPlatforms.find((platform: FeedPlatformEntity) => option === platform.Name)
    if (platform) {
      return `${platform.Name} (${platform.Type})`
    }
    return option
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="processing-rule.rightbar.import" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            selectInput('FeedPlatform')
              .optionItems(feedPlatforms.map((platform: FeedPlatformEntity) => platform.Name))
              .converter(makePlatformName),
            selectInput('ImportType').optionItems(Object.keys(ImportType)).converter(translator(translate, 'processing-rule')),
            checkboxInput('SetMarketMarkup'),
            checkboxInput('SetLimitMarkup'),
          ],
          inputState,
          setInputState,
          'processing-rule',
        )}

        <Button className="t4b-bg-dark-button mt-3 mr-2" onClick={() => dispatch(hideRightBar())}>
          <FormattedMessage id="cancel" tagName="span" />
        </Button>
        <Button className="t4b-bg-dark-button mt-3" onClick={handleLoad}>
          <FormattedMessage id="load" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default ImportProcessingRulesRightbar
