import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { FeedPlatformRuleEntity, MarkupType } from '../../entity/feeding'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addFeedPlatformSymbol, changeFeedPlatformSymbol, feedingConfigurationChanged, getFeedPlatformSymbols } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, cmselectInput, selectInput, textInput } from '../../utils/controls'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const FeedPlatformSymbolRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, FeedPlatformRuleEntity.schema)
  const [platformSymbols, setPlatformSymbols] = useState<any>([])
  const ref = useRef<any>(null)

  useEffect(() => {
    const url = new URL('/api/feedPlatform/symbols', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('feedPlatform', params.Platform)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPlatformSymbols(buildMultiselectOptionsFromArray(data)))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, dispatch])

  const handleSave = () => {
    if (!isValid()) {
      if (ref.current) {
        ref.current.open()
      }
      return
    }
    if (type === 'add' || type === 'clone') {
      dispatch(addFeedPlatformSymbol({ params, body: inputState }))
    } else if (type === 'modify') {
      dispatch(changeFeedPlatformSymbol({ params, body: inputState }))
    }
    dispatch(getFeedPlatformSymbols({ ...inputState, ...params }))
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const controls = buildControlsExtTwoPerLine(
    [
      cmselectInput('Symbols', platformSymbols || []),
      textInput('MaxLevels'),
      selectInput('MarkupType', Object.values(MarkupType)),
      textInput('MarkupBid'),
      textInput('MarkupAsk'),
      textInput('MinSpread').skipWhen(inputState.MarkupType === MarkupType.Fixed),
      textInput('MaxSpread').skipWhen(inputState.MarkupType !== MarkupType.MiniMax),
    ],
    inputState,
    setInputState,
    'feed-platform.symbol-map',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.${type}`} />,
          item: controls,
        }}
        ref={ref}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default FeedPlatformSymbolRightbar
