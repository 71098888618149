import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedAgregationEntity, FeedPlatformEntity } from '../../entity/feeding'
import { addFeedPlatform, changeFeedPlatform } from '../../redux/actions/execution/execution-actions'
import { feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, selectInput, textInput } from '../../utils/controls'
import * as yup from 'yup'
import { useTranslate } from '../../hooks/useTranslate'
import { folderNameSchema } from '../../utils/schema-utils'

interface IFeedAgregationRightbar extends IGatewayProp {
  data: any
}

const schema = {
  Name: folderNameSchema(),
  Connector: yup.string().required(),
  AggregatedFeeder: yup.number().required(),
  BackupFeedAggregationId: yup.number().required(),
}

const FeedPlatformRightbar: React.FC<IFeedAgregationRightbar> = ({ data: { type, item }, gateway }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new FeedPlatformEntity(item), schema)
  const dispatch = useDispatch()
  const { feedAgregations, feedPlatforms } = useSelector((state: any) => state.feedingConfiguration)
  const translate = useTranslate()

  useEffect(() => {
    const connector = gateway.Platforms.filter((item: any) => item.Type !== 'mtexec')
    if (feedAgregations && type === 'add') {
      setInputState(
        new FeedPlatformEntity({
          ...inputState,
          Connector: connector[0]?.Name ?? '',
          AggregatedFeeder: feedAgregations[0]?.Id ?? '',
        }),
      )
    }
  }, [feedAgregations]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    const data = new FeedPlatformEntity(inputState)
    if (type === 'add' || type === 'clone') {
      dispatch(addFeedPlatform(data))
    } else {
      dispatch(changeFeedPlatform(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const convertFeederOptions = (option: number) => {
    if (option === 0) {
      return translate('None')
    }
    const aggregatedFeeder = feedAgregations.find((aggregation: FeedAgregationEntity) => aggregation.Id === option)
    if (aggregatedFeeder) {
      return aggregatedFeeder.Name
    }
    return option
  }

  const alreadyExist = () => {
    if (type === 'add' && feedPlatforms.find((platform: FeedPlatformEntity) => platform.Name === inputState.Name)) {
      errors.Name = true
      return 'feed-platform-already-exists'
    }
    if (type === 'modify' && feedPlatforms.find((platform: FeedPlatformEntity) => platform.Name === inputState.Name && platform.Id !== inputState.Id)) {
      errors.Name = true
      return 'feed-platform-already-exists'
    }
    return ''
  }

  const notCover: string[] = []
  gateway.Platforms.forEach((platform: any) => {
    if (platform.Type !== 'mtexec') {
      notCover.push(platform.Name)
    }
  })

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`feed-platform.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name').errorMessage(alreadyExist()),
            selectInput('Connector', notCover || []),
            selectInput(
              'AggregatedFeeder',
              feedAgregations.map((aggregation: FeedAgregationEntity) => aggregation.Id),
              !item.Enabled && type !== 'clone',
              inputState,
              setInputState,
              convertFeederOptions,
            ),
            selectInput(
              'BackupFeedAggregationId',
              [0, ...feedAgregations.map((aggregation: FeedAgregationEntity) => aggregation.Id)],
              !item.Enabled && type !== 'clone',
              inputState,
              setInputState,
              convertFeederOptions,
            ),
          ],
          inputState,
          setInputState,
          'feed-platforms',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default withGateway(FeedPlatformRightbar)
