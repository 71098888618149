import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { convertPlatformFields, MtExecPlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, passwordInput, textInput, timespanInput } from '../../utils/controls'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'

interface IMtExecPlatformEdit extends IGatewayProp {
  data: any
  isValidParent?: () => boolean
}

const MtExecPlatformEdit: React.FC<IMtExecPlatformEdit> = React.memo(({ data: { item, type }, isValidParent, gateway, gatewayName }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new MtExecPlatformEntity(item), MtExecPlatformEntity.schema(type))
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(
    new ConnectionString(item.ConnectionString || {}),
    ConnectionString.schema(type),
  )
  const dispatch = useDispatch()
  const ref = useRef<any>(null)
  const dbRef = useRef<any>(null)

  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
      FileStorePath: item.FileStorePath,
      FileLogPath: item.FileLogPath,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)
  if (type === 'add' && isDefaultSettings.isDefaultSettings) {
    connErrors.Password = false
  }

  const dbEditor = (
    <DbConnectionEditor
      type={type}
      state={connectionString}
      setState={setConnectionString}
      touched={connTouched}
      setTouched={setConnTouched}
      errors={connErrors}
      isDefaultSettings={isDefaultSettings}
      setIsDefaultSettings={setDefaultDb}
    />
  )

  const editPlatform = buildControlsExtTwoPerLine(
    [
      textInput('SocketAcceptPort'),
      checkboxInput('UseLocalTime'),
      timespanInput('StartTime'),
      timespanInput('EndTime'),
      checkboxInput('SSLEnable'),
      textInput('Mt5Server'),
      textInput('Mt5ServerPort'),
      textInput('Mt5Login'),
      passwordInput('Mt5Password', type === 'add' ? 'setup' : 'change'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    const cond1 = isValid()
    let cond2 = isConnValid()
    if (isValidParent) {
      isValidParent()
    }

    if (!cond1) {
      ref?.current?.open()
    }

    if (!cond2) {
      dbRef?.current?.open()
    }

    if (!cond1 || !cond2) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
          },
        }),
      )
    }
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.edit" />,
          item: editPlatform,
        }}
        ref={ref}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
})

export default withGateway(MtExecPlatformEdit)
