import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { AutoSwitchEntity } from '../../entity/risk-tool-entity'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchAutoSwitchUpdate } from '../../redux/actions/risk-tool-actions'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, selectInput, textInput } from '../../utils/controls'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const schema = {
  RuleName: yup.string().required(),
  Groups: yup.string().required(),
  Accounts: yup.string().required(),
  SwitchTriggerValue: yup.number().required(),
  ReturnTriggerValue: yup.number().required(),
}

enum SwitchTriggerType {
  Currency = 'Currency',
  Points = 'Points',
  VolumePoints = 'VolumePoints',
  BalancePercent = 'BalancePercent',
}

enum SwitchTriggerDirection {
  Greater = 'Greater',
  Smaller = 'Smaller',
}

function convert(option: string) {
  if (option === SwitchTriggerType.Currency) {
    return 'PnL'
  }
  return option
}

const AutoSwitchRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const [settings, setSettings] = useState({
    Gateway: '',
    Symbols: [],
  })

  const dispatch = useDispatch()
  const [poolToProcessors, setPoolToProcessors] = useState(new Map())
  const { gateway } = useSelector((state: any) => state.gateways)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new AutoSwitchEntity(item), schema)
  const { data } = useSelector((state: any) => state.riskTool.autoSwitch)
  const mainRef = useRef<any>(null)
  const switchRef = useRef<any>(null)
  const returnRef = useRef<any>(null)

  useEffect(() => {
    if (gateway.Name) {
      const poolToProc = new Map()
      for (const pair of gateway.Processors) {
        if (poolToProc.has(pair.Pool)) {
          poolToProc.set(pair.Pool, [...poolToProc.get(pair.Pool), pair.Name])
        } else {
          poolToProc.set(pair.Pool, [pair.Name])
        }
      }
      const platformObject = gateway.Platforms.find((platform: any) => platform.Name === params.Platform)
      const newState = {
        Gateway: gateway.Name,
        Symbols: optionsToStrings(platformObject?.Symbols) || [],
      }

      setPoolToProcessors(poolToProc)
      setSettings({
        ...inputState,
        ...newState,
      })

      if (type === 'add') {
        const pool = gateway.Pools[0]?.Name ?? ''
        setInputState({
          ...inputState,
          OriginalPool: pool,
          SwitchToPool: gateway.Pools[1]?.Name,
          NewProcessor: (poolToProc.get(gateway.Pools[1]?.Name) && poolToProc.get(gateway.Pools[1]?.Name)[0]) ?? [],
          Symbol: optionsToStrings(platformObject?.Symbols)[0] ?? '',
        })
      }
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  const switchTrigger = buildControlsExtTwoPerLine(
    [
      selectInput('SwitchTriggerType').optionItems(Object.values(SwitchTriggerType)).converter(convert),
      blankInput(),
      selectInput('SwitchTriggerDirection', Object.values(SwitchTriggerDirection)),
      textInput('SwitchTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const returnTrigger = buildControlsExtTwoPerLine(
    [
      selectInput('ReturnTriggerType').optionItems(Object.values(SwitchTriggerType)).converter(convert),
      blankInput(),
      selectInput('ReturnTriggerDirection', Object.values(SwitchTriggerDirection)),
      textInput('ReturnTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const setOriginalPool = (newState: any) => {
    const updatedState = { ...newState }
    if (newState.OriginalPool === inputState.SwitchToPool) {
      const pool = gateway.Pools.find((pool: any) => pool.Name !== newState.OriginalPool) || {}
      updatedState.SwitchToPool = pool.Name
      updatedState.NewProcessor = poolToProcessors.get(updatedState.SwitchToPool) ? poolToProcessors.get(updatedState.SwitchToPool)[0] : ''
    }
    setInputState(updatedState)
  }

  const setProcessor = (newState: any) => {
    const updatedState = { ...newState }
    updatedState.NewProcessor = poolToProcessors.get(newState.SwitchToPool) ? poolToProcessors.get(newState.SwitchToPool)[0] : ''
    setInputState(updatedState)
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && data.find((item: any) => item.RuleName === inputState.RuleName)) {
      errors.RuleName = true
      return 'auto-switch.already-exist'
    }
    return ''
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('RuleName').errorMessage(alreadyExist()),
      textInput('Groups'),
      textInput('Accounts'),
      selectInput('Symbol', settings.Symbols),
      selectInput('OriginalPool', gateway?.Pools?.map((pool: any) => pool.Name) ?? [], false, inputState, setOriginalPool),
      selectInput(
        'SwitchToPool',
        gateway?.Pools?.filter((pool: any) => pool.Name !== inputState.OriginalPool).map((pool: any) => pool.Name) ?? [],
        false,
        inputState,
        setProcessor,
      ),
      selectInput('NewProcessor', poolToProcessors.get(inputState.SwitchToPool) ?? []),
      checkboxInput('AggregatedSwitch'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const handleClick = () => {
    if (!isValid()) {
      if (mainRef.current) {
        mainRef.current.open()
      }
      if (errors.SwitchTriggerValue && switchRef.current) {
        switchRef.current.open()
      }
      if (errors.ReturnTriggerValue && returnRef.current) {
        returnRef.current.open()
      }
      return
    }
    dispatch(fetchAutoSwitchUpdate({ action: type, body: { ...inputState }, params }))
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.title" />,
          item: inputs,
        }}
        ref={mainRef}
        isHidden={false}
      />
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.switch-trigger" />,
          item: switchTrigger,
        }}
        ref={switchRef}
      />
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.return-trigger" />,
          item: returnTrigger,
        }}
        ref={returnRef}
      />
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleClick}>
        <FormattedMessage id={type} tagName="span" />
      </Button>
    </>
  )
}

export default AutoSwitchRightbar
